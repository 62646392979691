import { FormattedMessage } from 'react-intl'
import { FormControl, FormHelperText } from '@mui/material'

import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'
import { MOTIVATION_KEYS } from './MotivationConstants'
import { RankedMotivation } from './RankedMotivation'

export const RankedMotivations = () => {
    return (
        <FormControl sx={styles.formControl}>
            <QuestionText required>
                <FormattedMessage
                    defaultMessage="In general, how important are the following motivations/experiences for your desire to recreate in the backcountry in winter?"
                    description="User Research Panel"
                />
            </QuestionText>
            <FormHelperText>
                <FormattedMessage
                    defaultMessage="Please rate each motivation/experience on the scale from ‘Not at all important’ to ‘Extremely important’."
                    description="User Research Panel"
                />
            </FormHelperText>
            {MOTIVATION_KEYS.map(motivation => (
                <RankedMotivation key={motivation} motivationField={motivation} />
            ))}
        </FormControl>
    )
}

const styles = {
    formControl: {
        width: '100%',
        margin: '4px 0',
    },
}
