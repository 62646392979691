import {
    AVALANCHE_TRAINING_AIMED_AT_AVALANCHE_PROFESSIONALS,
    ADVANCED_RECREATIONAL_AVALANCHE_SAFETY_COURSE,
    INTRODUCTORY_RECREATIONAL_AVALANCHE_SAFETY_COURSE_WITH_A_FIELD_COMPONENT,
} from './TrainingCourseConstants'

// This file is necessary to avoid circular dependencies
export const PERSONAL_RECREATION = 'personal_recreation'
export const LEADER_FOR_ORGANIZED_RECREATIONAL_GROUP = 'leader_for_organized_recreational_group'
export const CERTIFIED_MOUNTAIN_GUIDE = 'certified_mountain_guide'
export const CERTIFIED_SKI_GUIDE = 'certified_ski_guide'
export const MEMBER_OF_AN_ALPINE_SEARCH_AND_RESCUE_ORGANIZATION = 'member_of_an_alpine_search_and_rescue_organization'
export const MEMBER_OF_SKI_PATROL_MANAGING_AVALANCHE_RISK_AT_SKI_AREA =
    'member_of_ski_patrol_managing_avalanche_risk_at_ski_area'
export const AVALANCHE_SAFETY_TECHNICIAN_ENGINEER_MANAGING_AVALANCHE_RISK_FOR_INFRASTRUCTURE_OR_WORKSITES =
    'avalanche_safety_technician_engineer_managing_avalanche_risk_for_infrastructure_or_worksites'
export const AVALANCHE_SAFETY_EDUCATOR = 'avalanche_safety_educator'
export const PUBLIC_AVALANCHE_FORECASTER = 'public_avalanche_forecaster'
export const AVALANCHE_RESEARCHER = 'avalanche_researcher'
export const OTHER = 'other'

export const PERSONAL_INVOLVEMENT = 'personalInvolvement'
export const PERSONAL_INVOLVEMENT_OTHER = 'personalInvolvementOther'
export const EDUCATOR_CERTIFICATION = 'educatorCertification'
export const EDUCATOR_CERTIFICATION_OTHER = 'educatorCertificationOther'
export const EDUCATOR_PAID_COURSES_MULTIPLE_TIMES_PER_SEASON = 'educatorPaidCoursesMultipleTimesPerSeason'
export const CERTIFIED_MOUNTAIN_GUIDE_DAYS_WORKED_PER_SEASON = 'certifiedMountainGuideDaysWorkedPerSeason'
export const CERTIFIED_SKI_GUIDE_DAYS_WORKED_PER_SEASON = 'certifiedSkiGuideDaysWorkedPerSeason'
export const ALPINE_SAR_DAYS_WORKED_PER_SEASON = 'alpineSAROrgDaysWorkedPerSeason'
export const SKI_PATROL_MANAGING_RISK_SKI_AREA_DAYS_WORKED_PER_SEASON =
    'skiPatrolManagingRiskSkiAreaDaysWorkedPerSeason'
export const AVALANCHE_PROFESSIONAL_DAYS_WORKED_PER_SEASON = 'avalancheProfessionalDaysWorkedPerSeason'
export const AVALANCHE_SAFETY_EDUCATOR_DAYS_WORKED_PER_SEASON = 'avalancheSafetyEducatorDaysWorkedPerSeason'
export const PUBLIC_AVALANCHE_FORECASTER_DAYS_WORKED_PER_SEASON = 'publicAvalancheForecasterDaysWorkedPerSeason'
export const AVALANCHE_RESEARCHER_DAYS_WORKED_PER_SEASON = 'avalancheResearcherDaysWorkedPerSeason'
export const CERTIFIED_MOUNTAIN_GUIDE_YEAR_BECAME_PROFESSIONAL = 'certifiedMountainGuideYearBecameProfessional'
export const CERTIFIED_SKI_GUIDE_YEAR_BECAME_PROFESSIONAL = 'certifiedSkiGuideYearBecameProfessional'
export const ALPINE_SAR_YEAR_BECAME_PROFESSIONAL = 'alpineSAROrgYearBecameProfessional'
export const SKI_PATROL_MANAGING_RISK_SKI_AREA_YEAR_BECAME_PROFESSIONAL = 'skiPatrolManagingRiskSkiAreaYearProfessional'
export const AVALANCHE_PROFESSIONAL_YEAR_BECAME_PROFESSIONAL = 'avalancheProfessionalYearBecameProfessional'
export const AVALANCHE_SAFETY_EDUCATOR_YEAR_BECAME_PROFESSIONAL = 'avalancheSafetyEducatorYearBecameProfessional'
export const PUBLIC_AVALANCHE_FORECASTER_YEAR_BECAME_PROFESSIONAL = 'publicAvalancheForecasterYearBecameProfessional'
export const AVALANCHE_RESEARCHER_YEAR_BECAME_PROFESSIONAL = 'avalancheResearcherYearBecameProfessional'
export const PRACTICE_WITH_PEERS = 'practiceWithPeersAtASimilarExperienceLevel'
export const PERSONAL_EXPERIENCE_IN_THE_FIELD = 'personalExperienceInTheField'
export const MENTORSHIP = 'mentorshipFromAMoreExperiencedPerson'
export const FORMAL_AVALANCHE_SAFETY_COURSE = 'formalAvalancheSafetyCourseFromAvEdProvider'
export const SELF_STUDY = 'selfStudyOfBooksAndOnlineTutorials'
export const VIDEOS_OR_CONTENT_ON_SOCIAL_MEDIA = 'videosOrContentOnSocialMedia'
export const ENGAGING_IN_ONLINE_COMMUNITIES = 'engagingInOnlineCommunities'
export const KNOWLEDGE_SOURCE_OTHER = 'knowledgeSourceOther'
export const KNOWLEDGE_SOURCE_OTHER_TEXT = 'knowledgeSourceOtherText'
export const HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED = 'highestLevelTrainingCourseCompleted'
export const HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_OTHER = 'highestLevelTrainingCourseCompletedOther'
export const HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_YEAR = 'highestLevelTrainingCourseCompletedYear'
export const TRAINING_COURSE_COUNT = 'trainingCourseCount'

export const isLevelOneOrHigher = (trainingCourse: string) => {
    return (
        trainingCourse === INTRODUCTORY_RECREATIONAL_AVALANCHE_SAFETY_COURSE_WITH_A_FIELD_COMPONENT ||
        trainingCourse === ADVANCED_RECREATIONAL_AVALANCHE_SAFETY_COURSE ||
        trainingCourse === AVALANCHE_TRAINING_AIMED_AT_AVALANCHE_PROFESSIONALS ||
        trainingCourse === OTHER
    )
}
