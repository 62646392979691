import { FormattedMessage } from 'react-intl'

import { UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import { ImportanceRanking } from 'components/UserResearchPanel/CommonInputs/ImportanceRanking'
import { motivationMessages } from './MotivationConstants'
import { UserResearchPanelFormValues } from '../../validationSchema'
import { QuestionGroup } from 'components/UserResearchPanel/CommonInputs/QuestionGroup'

export const RankedMotivation = ({ motivationField }: { motivationField: keyof UserResearchPanelFormValues }) => {
    const { touched, errors } = UserResearchPanelSignupStore()

    return (
        <QuestionGroup
            question={<FormattedMessage {...motivationMessages[motivationField as keyof typeof motivationMessages]} />}
            required
            error={touched[motivationField] && !!errors[motivationField]}
            errorMessage={errors[motivationField as keyof typeof errors]}
        >
            <ImportanceRanking schemaField={motivationField} />
        </QuestionGroup>
    )
}
