import { FormattedMessage } from 'react-intl'
import { FormControl, FormHelperText, Box, FormControlLabel, Radio } from '@mui/material'

import { PROFESSIONAL_ROLE_QUESTION_KEYS, ProfessionalRoleKey } from './PersonalInvolvement'
import {
    PROFESSIONAL_DAYS_WORKED_PER_SEASON_OPTIONS,
    checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10,
    professionalDaysPerWinterMessages,
} from './ProfessionalDaysPerWinter'
import { messages as personalInvolvementMessages } from './PersonalInvolvement'
import { useHandleChange, UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import { YearSelect } from 'components/UserResearchPanel/CommonInputs/YearSelect'
import { commonMessages } from 'components/UserResearchPanel/CommonInputs/CommonMessages'
import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'
import { UserResearchPanelFormValues } from '../../validationSchema'

type Props = {
    professionalRoleKey: ProfessionalRoleKey
}

export const ProfessionalQuestions = ({ professionalRoleKey }: Props) => {
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()
    const {
        daysWorkedPerSeasonKey,
        yearBecameProfessionalKey,
        daysWorkedPerSeasonMessageKey,
        yearBecameProfessionalMessageKey,
    } = PROFESSIONAL_ROLE_QUESTION_KEYS[professionalRoleKey]

    const handleDaysWorkedPerSeasonChange = (daysWorkedPerSeason: string) => {
        handleChange(daysWorkedPerSeasonKey as keyof UserResearchPanelFormValues, daysWorkedPerSeason)
    }

    const daysWorkedPerSeasonIsMoreThan10 =
        typeof formData[daysWorkedPerSeasonKey as keyof typeof formData] === 'string' &&
        checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10(
            formData[daysWorkedPerSeasonKey as keyof typeof formData] as string
        )

    return (
        <>
            <FormControl
                sx={styles.formControl}
                error={
                    touched[daysWorkedPerSeasonKey as keyof typeof touched] &&
                    !!errors[daysWorkedPerSeasonKey as keyof typeof errors]
                }
                aria-invalid={
                    touched[daysWorkedPerSeasonKey as keyof typeof touched] &&
                    !!errors[daysWorkedPerSeasonKey as keyof typeof errors]
                }
            >
                <QuestionText required>
                    <FormattedMessage
                        {...personalInvolvementMessages[
                            daysWorkedPerSeasonMessageKey as keyof typeof personalInvolvementMessages
                        ]}
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectOneOption} />
                </FormHelperText>
                <Box sx={styles.flex}>
                    {PROFESSIONAL_DAYS_WORKED_PER_SEASON_OPTIONS.map(option => (
                        <FormControlLabel
                            key={option.name}
                            control={
                                <Radio
                                    checked={formData[daysWorkedPerSeasonKey as keyof typeof formData] === option.name}
                                    onChange={() => handleDaysWorkedPerSeasonChange(option.name)}
                                    inputProps={{
                                        'aria-invalid':
                                            touched[daysWorkedPerSeasonKey as keyof typeof touched] &&
                                            !!errors[daysWorkedPerSeasonKey as keyof typeof errors],
                                    }}
                                />
                            }
                            label={<FormattedMessage {...professionalDaysPerWinterMessages[option.name]} />}
                        />
                    ))}
                </Box>
            </FormControl>
            {daysWorkedPerSeasonIsMoreThan10 && (
                <FormControl
                    sx={styles.formControl}
                    error={
                        touched[yearBecameProfessionalKey as keyof typeof touched] &&
                        !!errors[yearBecameProfessionalKey as keyof typeof errors]
                    }
                >
                    <QuestionText required>
                        <FormattedMessage
                            {...personalInvolvementMessages[
                                yearBecameProfessionalMessageKey as keyof typeof personalInvolvementMessages
                            ]}
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Please enter the year even if it is just approximate."
                        />
                    </FormHelperText>
                    <YearSelect dataKey={yearBecameProfessionalKey as keyof typeof formData} />
                </FormControl>
            )}
        </>
    )
}

const styles = {
    flex: {
        display: 'flex' as const,
        gap: '1px',
        flexDirection: 'column' as const,
        flexWrap: 'wrap' as const,
    },
    formControl: {
        marginTop: 4,
        width: '100%',
    },
    yearInput: {
        width: 300,
        marginTop: 1,
    },
}
