import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'

import { useHandleChange, UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import { HOW_DID_YOU_HEAR, HOW_DID_YOU_HEAR_OTHER } from '../validationSchema'
import { TextInput } from '../CommonInputs/TextInput'
import { commonMessages } from '../CommonInputs/CommonMessages'
import { QuestionText } from '../CommonInputs/QuestionText'

const OTHER_KEY = 'Other'

const messages = defineMessages({
    websiteOption: {
        id: 'UserResearchPanel.HowDidYouHear.website',
        defaultMessage: 'Avalanche Canada website',
    },
    socialMediaOption: {
        id: 'UserResearchPanel.HowDidYouHear.socialMedia',
        defaultMessage: 'Avalanche Canada social media',
    },
    otherSocialMediaOption: {
        id: 'UserResearchPanel.HowDidYouHear.otherSocialMedia',
        defaultMessage: 'Other social media',
    },
    podcastOption: {
        id: 'UserResearchPanel.HowDidYouHear.podcast',
        defaultMessage: 'Podcast/webinar',
    },
    trainingOption: {
        id: 'UserResearchPanel.HowDidYouHear.training',
        defaultMessage: 'Avalanche Canada Training course',
    },
    aspectOption: {
        id: 'UserResearchPanel.HowDidYouHear.aspect',
        defaultMessage: 'The Aspect',
    },
    clubOption: {
        id: 'UserResearchPanel.HowDidYouHear.club',
        defaultMessage: 'Club or group',
    },
    wordOfMouthOption: {
        id: 'UserResearchPanel.HowDidYouHear.wordOfMouth',
        defaultMessage: 'Word-of-mouth',
    },
    otherPlaceholder: {
        id: 'UserResearchPanel.HowDidYouHear.otherPlaceholder',
        defaultMessage: 'Please specify',
    },
})

export const HowDidYouHear = () => {
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const selected = (formData.howDidYouHear as string[]) || []
    const handleChange = useHandleChange()
    const { formatMessage } = useIntl()

    const options = [
        formatMessage(messages.websiteOption),
        formatMessage(messages.socialMediaOption),
        formatMessage(messages.otherSocialMediaOption),
        formatMessage(messages.podcastOption),
        formatMessage(messages.trainingOption),
        formatMessage(messages.aspectOption),
        formatMessage(messages.clubOption),
        formatMessage(messages.wordOfMouthOption),
    ]

    const handleSelectChange = (option: string) => {
        const updatedSelection = selected.includes(option)
            ? selected.filter(item => item !== option)
            : [...selected, option]

        handleChange(HOW_DID_YOU_HEAR, updatedSelection)
    }

    const isOtherSelected = selected.includes(OTHER_KEY)

    return (
        <FormControl
            sx={styles.formControlExtraSpace}
            error={touched[HOW_DID_YOU_HEAR] && !!errors[HOW_DID_YOU_HEAR]}
            aria-invalid={touched[HOW_DID_YOU_HEAR] && !!errors[HOW_DID_YOU_HEAR]}
        >
            <QuestionText required>
                <FormattedMessage
                    description="User Research Panel"
                    defaultMessage="How did you hear about this Avalanche Canada initiative?"
                />
            </QuestionText>
            <FormHelperText>
                <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
            </FormHelperText>
            <FormGroup>
                {options.map(option => (
                    <FormControlLabel
                        key={option}
                        control={
                            <Checkbox checked={selected.includes(option)} onChange={() => handleSelectChange(option)} />
                        }
                        label={option}
                    />
                ))}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selected.includes(OTHER_KEY)}
                            onChange={() => handleSelectChange(OTHER_KEY)}
                        />
                    }
                    label="Other"
                />
                {isOtherSelected && (
                    <TextInput
                        dataKey={HOW_DID_YOU_HEAR_OTHER}
                        placeholder={formatMessage(messages.otherPlaceholder)}
                        sx={{ ml: 3, mt: 1 }}
                    />
                )}
            </FormGroup>
        </FormControl>
    )
}

const styles = {
    formControlExtraSpace: {
        marginTop: 4,
    },
}
