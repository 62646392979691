import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'

import { CA } from 'components/UserResearchPanel/SignupSteps/CountrySelect'
import { UserResearchPanelSignupStore, useHandleBlur, useHandleChange } from 'stores/UserResearchPanelSignupStore'
import { PROVINCE_OR_STATE } from '../validationSchema'

const messages = defineMessages({
    chooseProvince: {
        id: 'provinceSelect.chooseProvince',
        defaultMessage: 'Choose a province',
    },
    chooseState: {
        id: 'provinceSelect.chooseState',
        defaultMessage: 'Choose a state',
    },
})

export default function CountrySelect() {
    const intl = useIntl()
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()
    const handleBlur = useHandleBlur()

    const options = formData.country === CA ? CANADIAN_PROVINCES : US_STATES
    const label =
        formData.country === CA ? intl.formatMessage(messages.chooseProvince) : intl.formatMessage(messages.chooseState)
    const value = formData.provinceOrState ? options.find(o => o.code === formData.provinceOrState) : null

    return (
        <Autocomplete
            id="province-select"
            sx={{ width: 300 }}
            options={options}
            autoHighlight
            value={value}
            onBlur={() => handleBlur(PROVINCE_OR_STATE, formData[PROVINCE_OR_STATE] as string)}
            onChange={(_, newValue) => handleChange(PROVINCE_OR_STATE, newValue?.code || '')}
            getOptionLabel={option => intl.formatMessage(option.label)}
            renderOption={(props, option) => (
                <Box key={option.code} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {intl.formatMessage(option.label)}
                </Box>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    error={touched[PROVINCE_OR_STATE] && !!errors[PROVINCE_OR_STATE]}
                    helperText={touched[PROVINCE_OR_STATE] && errors[PROVINCE_OR_STATE]}
                />
            )}
        />
    )
}

// Helpers and constants

const provinces = defineMessages({
    AB: { id: 'province.AB', defaultMessage: 'Alberta' },
    BC: { id: 'province.BC', defaultMessage: 'British Columbia' },
    MB: { id: 'province.MB', defaultMessage: 'Manitoba' },
    ON: { id: 'province.ON', defaultMessage: 'Ontario' },
    QC: { id: 'province.QC', defaultMessage: 'Quebec' },
    SK: { id: 'province.SK', defaultMessage: 'Saskatchewan' },
    NB: { id: 'province.NB', defaultMessage: 'New Brunswick' },
    NL: { id: 'province.NL', defaultMessage: 'Newfoundland and Labrador' },
    NS: { id: 'province.NS', defaultMessage: 'Nova Scotia' },
    PE: { id: 'province.PE', defaultMessage: 'Prince Edward Island' },
    YT: { id: 'province.YT', defaultMessage: 'Yukon' },
    NT: { id: 'province.NT', defaultMessage: 'Northwest Territories' },
    NU: { id: 'province.NU', defaultMessage: 'Nunavut' },
})

const states = defineMessages({
    AL: { id: 'state.AL', defaultMessage: 'Alabama' },
    AK: { id: 'state.AK', defaultMessage: 'Alaska' },
    AZ: { id: 'state.AZ', defaultMessage: 'Arizona' },
    AR: { id: 'state.AR', defaultMessage: 'Arkansas' },
    CA: { id: 'state.CA', defaultMessage: 'California' },
    CO: { id: 'state.CO', defaultMessage: 'Colorado' },
    CT: { id: 'state.CT', defaultMessage: 'Connecticut' },
    DE: { id: 'state.DE', defaultMessage: 'Delaware' },
    FL: { id: 'state.FL', defaultMessage: 'Florida' },
    GA: { id: 'state.GA', defaultMessage: 'Georgia' },
    HI: { id: 'state.HI', defaultMessage: 'Hawaii' },
    ID: { id: 'state.ID', defaultMessage: 'Idaho' },
    IL: { id: 'state.IL', defaultMessage: 'Illinois' },
    IN: { id: 'state.IN', defaultMessage: 'Indiana' },
    IA: { id: 'state.IA', defaultMessage: 'Iowa' },
    KS: { id: 'state.KS', defaultMessage: 'Kansas' },
    KY: { id: 'state.KY', defaultMessage: 'Kentucky' },
    LA: { id: 'state.LA', defaultMessage: 'Louisiana' },
    ME: { id: 'state.ME', defaultMessage: 'Maine' },
    MD: { id: 'state.MD', defaultMessage: 'Maryland' },
    MA: { id: 'state.MA', defaultMessage: 'Massachusetts' },
    MI: { id: 'state.MI', defaultMessage: 'Michigan' },
    MN: { id: 'state.MN', defaultMessage: 'Minnesota' },
    MS: { id: 'state.MS', defaultMessage: 'Mississippi' },
    MO: { id: 'state.MO', defaultMessage: 'Missouri' },
    MT: { id: 'state.MT', defaultMessage: 'Montana' },
    NE: { id: 'state.NE', defaultMessage: 'Nebraska' },
    NV: { id: 'state.NV', defaultMessage: 'Nevada' },
    NH: { id: 'state.NH', defaultMessage: 'New Hampshire' },
    NJ: { id: 'state.NJ', defaultMessage: 'New Jersey' },
    NM: { id: 'state.NM', defaultMessage: 'New Mexico' },
    NY: { id: 'state.NY', defaultMessage: 'New York' },
    NC: { id: 'state.NC', defaultMessage: 'North Carolina' },
    ND: { id: 'state.ND', defaultMessage: 'North Dakota' },
    OH: { id: 'state.OH', defaultMessage: 'Ohio' },
    OK: { id: 'state.OK', defaultMessage: 'Oklahoma' },
    OR: { id: 'state.OR', defaultMessage: 'Oregon' },
    PA: { id: 'state.PA', defaultMessage: 'Pennsylvania' },
    RI: { id: 'state.RI', defaultMessage: 'Rhode Island' },
    SC: { id: 'state.SC', defaultMessage: 'South Carolina' },
    SD: { id: 'state.SD', defaultMessage: 'South Dakota' },
    TN: { id: 'state.TN', defaultMessage: 'Tennessee' },
    TX: { id: 'state.TX', defaultMessage: 'Texas' },
    UT: { id: 'state.UT', defaultMessage: 'Utah' },
    VA: { id: 'state.VA', defaultMessage: 'Virginia' },
    WA: { id: 'state.WA', defaultMessage: 'Washington' },
    WV: { id: 'state.WV', defaultMessage: 'West Virginia' },
    WI: { id: 'state.WI', defaultMessage: 'Wisconsin' },
    WY: { id: 'state.WY', defaultMessage: 'Wyoming' },
})

interface ProvinceType {
    code: string
    label: MessageDescriptor
    suggested?: boolean
}

const CANADIAN_PROVINCES: readonly ProvinceType[] = [
    {
        code: 'AB',
        label: provinces.AB,
    },
    {
        code: 'BC',
        label: provinces.BC,
    },
    {
        code: 'NB',
        label: provinces.NB,
    },
    {
        code: 'NL',
        label: provinces.NL,
    },
    {
        code: 'MB',
        label: provinces.MB,
    },
    {
        code: 'NS',
        label: provinces.NS,
    },
    {
        code: 'PE',
        label: provinces.PE,
    },
    {
        code: 'YT',
        label: provinces.YT,
    },
    {
        code: 'NT',
        label: provinces.NT,
    },
    {
        code: 'NU',
        label: provinces.NU,
    },
    {
        code: 'ON',
        label: provinces.ON,
    },
    {
        code: 'QC',
        label: provinces.QC,
    },
    {
        code: 'SK',
        label: provinces.SK,
    },
]

const US_STATES: readonly ProvinceType[] = [
    {
        code: 'AL',
        label: states.AL,
    },
    {
        code: 'AK',
        label: states.AK,
    },
    {
        code: 'AZ',
        label: states.AZ,
    },
    {
        code: 'AR',
        label: states.AR,
    },
    {
        code: 'CA',
        label: states.CA,
    },
    {
        code: 'CO',
        label: states.CO,
    },
    {
        code: 'CT',
        label: states.CT,
    },
    {
        code: 'DE',
        label: states.DE,
    },
    {
        code: 'FL',
        label: states.FL,
    },
    {
        code: 'GA',
        label: states.GA,
    },
    {
        code: 'HI',
        label: states.HI,
    },
    {
        code: 'ID',
        label: states.ID,
    },
    {
        code: 'IL',
        label: states.IL,
    },
    {
        code: 'IN',
        label: states.IN,
    },
    {
        code: 'IA',
        label: states.IA,
    },
    {
        code: 'KS',
        label: states.KS,
    },
    {
        code: 'KY',
        label: states.KY,
    },
    {
        code: 'LA',
        label: states.LA,
    },
    {
        code: 'ME',
        label: states.ME,
    },
    {
        code: 'MD',
        label: states.MD,
    },
    {
        code: 'MA',
        label: states.MA,
    },
    {
        code: 'MI',
        label: states.MI,
    },
    {
        code: 'MN',
        label: states.MN,
    },
    {
        code: 'MS',
        label: states.MS,
    },
    {
        code: 'MO',
        label: states.MO,
    },
    {
        code: 'MT',
        label: states.MT,
    },
    {
        code: 'NE',
        label: states.NE,
    },
    {
        code: 'NV',
        label: states.NV,
    },
    {
        code: 'NH',
        label: states.NH,
    },
    {
        code: 'NJ',
        label: states.NJ,
    },
    {
        code: 'NM',
        label: states.NM,
    },
    {
        code: 'NY',
        label: states.NY,
    },
    {
        code: 'NC',
        label: states.NC,
    },
    {
        code: 'ND',
        label: states.ND,
    },
    {
        code: 'OH',
        label: states.OH,
    },
    {
        code: 'OK',
        label: states.OK,
    },
    {
        code: 'OR',
        label: states.OR,
    },
    {
        code: 'PA',
        label: states.PA,
    },
    {
        code: 'RI',
        label: states.RI,
    },
    {
        code: 'SC',
        label: states.SC,
    },
    {
        code: 'SD',
        label: states.SD,
    },
    {
        code: 'TN',
        label: states.TN,
    },
    {
        code: 'TX',
        label: states.TX,
    },
    {
        code: 'UT',
        label: states.UT,
    },
    {
        code: 'VA',
        label: states.VA,
    },
    {
        code: 'WA',
        label: states.WA,
    },
    {
        code: 'WV',
        label: states.WV,
    },
    {
        code: 'WI',
        label: states.WI,
    },
    {
        code: 'WY',
        label: states.WY,
    },
]
