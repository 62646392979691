import { FormattedMessage } from 'react-intl'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'

import { UserResearchPanelSignupStore, useHandleChange } from 'stores/UserResearchPanelSignupStore'
import { importanceMessages, IMPORTANCE_OPTIONS } from './Importance'
import { UserResearchPanelFormValues } from '../validationSchema'

export const ImportanceRanking = ({ schemaField }: { schemaField: keyof UserResearchPanelFormValues }) => {
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()

    return (
        <RadioGroup
            value={formData[schemaField as keyof typeof formData] || ''}
            onChange={e => handleChange(schemaField, e.target.value)}
            sx={styles.radioGroup}
            aria-invalid={touched[schemaField as keyof typeof touched] && !!errors[schemaField as keyof typeof errors]}
        >
            {IMPORTANCE_OPTIONS.map(option => (
                <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio />}
                    label={<FormattedMessage {...importanceMessages[option]} />}
                    sx={styles.radioLabel}
                />
            ))}
        </RadioGroup>
    )
}

const styles = {
    radioGroup: {
        display: 'flex',
        flexDirection: 'column' as const,
    },
    radioLabel: {
        margin: 0,
        '& .MuiFormControlLabel-label': {
            fontSize: '1rem',
            lineHeight: 1.4,
        },
    },
}
