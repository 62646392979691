import { ReactNode } from 'react'

import { Typography, Grid, Box } from '@mui/material'
import { Lightbox } from 'components/images/Lightbox'

export const ATESRow = ({
    title,
    description,
    cloudinaryUrl,
    width,
    height,
    caption,
    children,
}: {
    title: string | ReactNode
    description: string
    cloudinaryUrl: string
    width: number
    height: number
    caption: string | ReactNode
    children?: ReactNode
}) => {
    return (
        <Box sx={styles.box}>
            <Grid container rowSpacing={0} columnSpacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" component="h3" sx={styles.title}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                    <Box>
                        <Typography>{description}</Typography>
                    </Box>
                    <Box sx={styles.children}>{children}</Box>
                </Grid>
                <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 1 } }}>
                    <Lightbox
                        src={cloudinaryUrl}
                        alt={title as string}
                        width={width}
                        height={height}
                        caption={caption}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

const styles = {
    box: {
        marginTop: '24px',
        borderTop: '1px solid var(--gray-light)',
        paddingTop: '4px',
    },
    children: {
        marginTop: 1,
        marginBottom: 1,
    },
    title: {
        marginTop: 2,
        marginBottom: 1,
    },
}
