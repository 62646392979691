import * as Yup from 'yup'
import { IntlShape } from 'react-intl'

import { messages } from './ValidationSchemaMessages'
import {
    SNOWSHOEING_OR_WINTER_HIKING,
    ICE_CLIMBING,
    BACKCOUNTRY_SKIING_SNOWBOARDING,
    MOUNTAIN_SNOWMOBILING_SNOWBIKING,
    OUT_OF_BOUNDS_SKIING,
    ALPINE_MOUNTAINEERING,
} from './SignupSteps/BackcountryActivities/Activities'
import {
    AVALANCHE_RESEARCHER,
    AVALANCHE_SAFETY_EDUCATOR,
    AVALANCHE_SAFETY_TECHNICIAN_ENGINEER_MANAGING_AVALANCHE_RISK_FOR_INFRASTRUCTURE_OR_WORKSITES,
    CERTIFIED_MOUNTAIN_GUIDE,
    CERTIFIED_SKI_GUIDE,
    MEMBER_OF_AN_ALPINE_SEARCH_AND_RESCUE_ORGANIZATION,
    MEMBER_OF_SKI_PATROL_MANAGING_AVALANCHE_RISK_AT_SKI_AREA,
    PUBLIC_AVALANCHE_FORECASTER,
} from './SignupSteps/Experience/ExperienceConstants'
import {
    ALPINE_SAR_DAYS_WORKED_PER_SEASON,
    ALPINE_SAR_YEAR_BECAME_PROFESSIONAL,
    AVALANCHE_PROFESSIONAL_DAYS_WORKED_PER_SEASON,
    AVALANCHE_PROFESSIONAL_YEAR_BECAME_PROFESSIONAL,
    AVALANCHE_RESEARCHER_DAYS_WORKED_PER_SEASON,
    AVALANCHE_RESEARCHER_YEAR_BECAME_PROFESSIONAL,
    AVALANCHE_SAFETY_EDUCATOR_DAYS_WORKED_PER_SEASON,
    AVALANCHE_SAFETY_EDUCATOR_YEAR_BECAME_PROFESSIONAL,
    CERTIFIED_MOUNTAIN_GUIDE_DAYS_WORKED_PER_SEASON,
    CERTIFIED_MOUNTAIN_GUIDE_YEAR_BECAME_PROFESSIONAL,
    CERTIFIED_SKI_GUIDE_DAYS_WORKED_PER_SEASON,
    CERTIFIED_SKI_GUIDE_YEAR_BECAME_PROFESSIONAL,
    EDUCATOR_CERTIFICATION,
    EDUCATOR_CERTIFICATION_OTHER,
    EDUCATOR_PAID_COURSES_MULTIPLE_TIMES_PER_SEASON,
    ENGAGING_IN_ONLINE_COMMUNITIES,
    FORMAL_AVALANCHE_SAFETY_COURSE,
    HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED,
    HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_OTHER,
    HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_YEAR,
    KNOWLEDGE_SOURCE_OTHER,
    KNOWLEDGE_SOURCE_OTHER_TEXT,
    MENTORSHIP,
    PERSONAL_EXPERIENCE_IN_THE_FIELD,
    PERSONAL_INVOLVEMENT_OTHER,
    PRACTICE_WITH_PEERS,
    PUBLIC_AVALANCHE_FORECASTER_DAYS_WORKED_PER_SEASON,
    PUBLIC_AVALANCHE_FORECASTER_YEAR_BECAME_PROFESSIONAL,
    SELF_STUDY,
    SKI_PATROL_MANAGING_RISK_SKI_AREA_DAYS_WORKED_PER_SEASON,
    SKI_PATROL_MANAGING_RISK_SKI_AREA_YEAR_BECAME_PROFESSIONAL,
    TRAINING_COURSE_COUNT,
    VIDEOS_OR_CONTENT_ON_SOCIAL_MEDIA,
} from './SignupSteps/Experience/ExperienceConstants'
import { PERSONAL_INVOLVEMENT } from './SignupSteps/Experience/ExperienceConstants'
import { isLevelOneOrHigher } from './SignupSteps/Experience/ExperienceConstants'
import {
    AVALANCHE_FORECAST_CHECKED_FREQUENCY,
    AVALANCHE_FORECAST_USAGE,
    BEING_AWAY_FROM_CROWDS,
    BEING_IN_NATURE,
    CHALLENGING_IMPROVING_YOUR_TECHNICAL_SKILLS,
    COMPLETING_CLASSIC_WELL_KNOWN_RESPECTED_TRIPS,
    DECISION_MAKING_CONTRIBUTION,
    ENJOYING_POWDER_SNOW_CONDITIONS_PERMITTING,
    EXERCISING_AND_WORKING_ON_YOUR_PHYSICAL_FITNESS,
    EXPERIENCING_REMOTENESS,
    EXPERIENCING_RISK,
    EXPERIENCING_THE_THRILL_EXHAILERATION_AND_RUSH,
    EXPLORING_NEW_OR_DIFFERENT_PLACES,
    FEELING_CAREFREE_AND_OR_RELAXED,
    GPS_TRACKING_PLATFORM_OTHER,
    GPS_TRACKING_PLATFORMS,
    INFORMATION_SOURCES_CONSULTED,
    INFORMATION_SOURCES_CONSULTED_OTHER,
    NAVIGATING_THROUGH_CHALLENGING_TERRAIN,
    PRACTICING_IMPROVING_YOUR_RISK_MGMT_SKILLS,
    REACHING_A_SUMMIT_OR_SIMILAR_TRIP_OBJECTIVE,
    REGIONAL_AVALANCHE_FORECAST,
    SAFETY_EQUIPMENT_TYPICALLY_USED,
    SHARING_A_STORY_PICTURE_OR_VIDEO_ON_SOCIAL_MEDIA,
    SHARING_YOUR_OUTDOOR_SKILLS_AND_KNOWLEDGE_WITH_OTHERS,
    SPENDING_TIME_WITH_FAMILY_AND_OR_FRIENDS,
    TRACK_WITH_GPS,
} from './SignupSteps/Motivations/MotivationConstants'
import { checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10 } from './SignupSteps/Experience/ProfessionalDaysPerWinter'

export const CURRENT_YEAR = new Date().getFullYear()
export const YEAR_OPTIONS = Array.from({ length: 101 }, (_, i) => CURRENT_YEAR - i)
export const MIN_YEAR = 1900
const MAX_BIRTH_YEAR = new Date().getFullYear() - 18
export const MAX_YEAR = new Date().getFullYear()

// Step 1: CONSENT
export const INFORMED_CONSENT = 'informedConsent'
export const createConsentSchema = (intl: IntlShape) =>
    Yup.object().shape({
        [INFORMED_CONSENT]: Yup.boolean()
            .required(intl.formatMessage(messages.informedConsentRequired))
            .oneOf([true], intl.formatMessage(messages.informedConsentRequired)),
    })

// Step 2: DATA_PRIVACY
export const LINKING_DATA_CONSENT = 'linkingDataConsent'
export const createDataPrivacySchema = (intl: IntlShape) =>
    Yup.object().shape({
        [LINKING_DATA_CONSENT]: Yup.boolean()
            .oneOf([true, false], intl.formatMessage(messages.dataPrivacyRequired))
            .required(intl.formatMessage(messages.dataPrivacyRequired)),
    })

// Step 3: BACKGROUND
export const GENDER = 'gender'
export const GENDER_OTHER = 'genderOther'
export const YEAR_OF_BIRTH = 'yearOfBirth'
export const COUNTRY = 'country'
export const PROVINCE_OR_STATE = 'provinceOrState'
export const CITY = 'city'
export const POSTAL_CODE = 'postalCode'
export const EMAIL = 'email'
export const HOW_DID_YOU_HEAR = 'howDidYouHear'
export const HOW_DID_YOU_HEAR_OTHER = 'howDidYouHearOther'

export const createBackgroundSchema = (intl: IntlShape) =>
    Yup.object().shape({
        [GENDER]: Yup.string().required(intl.formatMessage(messages.genderRequired)),
        [GENDER_OTHER]: Yup.string().nullable(),
        [YEAR_OF_BIRTH]: Yup.number()
            .min(MIN_YEAR, intl.formatMessage(messages.yearOfBirthMin))
            .max(MAX_BIRTH_YEAR, intl.formatMessage(messages.yearOfBirthMinValue))
            .required(intl.formatMessage(messages.yearOfBirthRequired)),
        [COUNTRY]: Yup.string().required(intl.formatMessage(messages.countryRequired)),
        [PROVINCE_OR_STATE]: Yup.string().nullable(),
        [CITY]: Yup.string().required(intl.formatMessage(messages.cityRequired)),
        [POSTAL_CODE]: Yup.string()
            .required(intl.formatMessage(messages.postalCodeRequired))
            .min(3, intl.formatMessage(messages.postalCodeMin)),
        [EMAIL]: Yup.string()
            .email(intl.formatMessage(messages.emailInvalid))
            .required(intl.formatMessage(messages.emailRequired)),
        [HOW_DID_YOU_HEAR]: Yup.array()
            .of(Yup.string())
            .min(1, intl.formatMessage(messages.selectAtLeastOne))
            .required(intl.formatMessage(messages.selectAtLeastOne)),
        [HOW_DID_YOU_HEAR_OTHER]: Yup.string().nullable(),
    })

// Step 4: BACKCOUNTRY_ACTIVITIES
export const BACKCOUNTRY_ACTIVITIES_FIRST = 'backcountryActivitiesFirst'
export const BACKCOUNTRY_ACTIVITIES_SECOND = 'backcountryActivitiesSecond'
export const BACKCOUNTRY_ACTIVITIES_THIRD = 'backcountryActivitiesThird'
export const BACKCOUNTRY_ACTIVITIES_OTHER = 'backcountryActivitiesOther'
export const SNOWSHOEING_TRAIL_FREQUENCY = 'snowshoeingTrailFrequency'
export const ICE_CLIMBING_EXPOSURE_FREQUENCY = 'iceClimbingExposureFrequency'
export const BACKCOUNTRY_EXPERIENCE_WINTERS = 'backcountryExperienceWinters'
export const BACKCOUNTRY_EXPERIENCE_DAYS = 'backcountryExperienceDays'
export const REGION = 'region'
export const TIME_PERIOD = 'timePeriod'
export const WEEKDAYS_IN_BACKCOUNTRY = 'weekDaysInBackcountry'
// ======== Terrain Frequency questions ========
// Snowshoeing, Hike, Mountaineering
export const SHM_ACTIVITIES = [SNOWSHOEING_OR_WINTER_HIKING, ALPINE_MOUNTAINEERING]
export const TERRAIN_FREQUENCIES_SHM_NON_AVALANCHE = 'terrainFrequenciesSHMNonAvalanche'
export const TERRAIN_FREQUENCIES_SHM_SIMPLE = 'terrainFrequenciesSHMSimple'
export const TERRAIN_FREQUENCIES_SHM_CHALLENGING = 'terrainFrequenciesSHMChallenging'
export const TERRAIN_FREQUENCIES_SHM_COMPLEX = 'terrainFrequenciesSHMComplex'
export const TERRAIN_FREQUENCIES_SHM_EXTREME = 'terrainFrequenciesSHMExtreme'
// Ice Climbing
export const TERRAIN_FREQUENCIES_ICE_CLIMBING_NON_AVALANCHE = 'terrainFrequenciesIceClimbingNonAvalanche'
export const TERRAIN_FREQUENCIES_ICE_CLIMBING_SIMPLE = 'terrainFrequenciesIceClimbingSimple'
export const TERRAIN_FREQUENCIES_ICE_CLIMBING_CHALLENGING = 'terrainFrequenciesIceClimbingChallenging'
export const TERRAIN_FREQUENCIES_ICE_CLIMBING_COMPLEX = 'terrainFrequenciesIceClimbingComplex'
export const TERRAIN_FREQUENCIES_ICE_CLIMBING_EXTREME = 'terrainFrequenciesIceClimbingExtreme'
// Backcountry Skiing, out of bounds skiing, snowmobiling
export const BOS_ACTIVITIES = [BACKCOUNTRY_SKIING_SNOWBOARDING, OUT_OF_BOUNDS_SKIING, MOUNTAIN_SNOWMOBILING_SNOWBIKING]
export const TERRAIN_FREQUENCIES_BOS_SIMPLE = 'terrainFrequenciesBOSSimple'
export const TERRAIN_FREQUENCIES_BOS_CHALLENGING = 'terrainFrequenciesBOSChallenging'
export const TERRAIN_FREQUENCIES_BOS_COMPLEX = 'terrainFrequenciesBOSComplex'
export const TERRAIN_FREQUENCIES_BOS_EXTREME = 'terrainFrequenciesBOSExtreme'
// ======== Terrain Frequency questions ========

export const createBackcountryActivitiesSchema = (intl: IntlShape) =>
    Yup.object().shape({
        [BACKCOUNTRY_ACTIVITIES_FIRST]: Yup.string().required(
            intl.formatMessage(messages.mostCommonBackcountryActivityRequired)
        ),
        [BACKCOUNTRY_ACTIVITIES_SECOND]: Yup.string().nullable(),
        [BACKCOUNTRY_ACTIVITIES_THIRD]: Yup.string().nullable(),
        [BACKCOUNTRY_ACTIVITIES_OTHER]: Yup.string().nullable(),
        [SNOWSHOEING_TRAIL_FREQUENCY]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: SNOWSHOEING_OR_WINTER_HIKING,
            then: schema => schema.required(undefined),
        }),
        [ICE_CLIMBING_EXPOSURE_FREQUENCY]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: ICE_CLIMBING,
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [BACKCOUNTRY_EXPERIENCE_WINTERS]: Yup.string().required(intl.formatMessage(messages.numberWintersRequired)),
        [BACKCOUNTRY_EXPERIENCE_DAYS]: Yup.string().required(intl.formatMessage(messages.daysPerWinterRequired)),
        [REGION]: Yup.array()
            .of(Yup.string())
            .min(1, intl.formatMessage(messages.selectAtLeastOne))
            .required(intl.formatMessage(messages.regionRequired)),
        [TIME_PERIOD]: Yup.array()
            .of(Yup.string())
            .min(1, intl.formatMessage(messages.selectAtLeastOne))
            .required(intl.formatMessage(messages.timePeriodRequired)),
        [WEEKDAYS_IN_BACKCOUNTRY]: Yup.array()
            .of(Yup.string())
            .min(1, intl.formatMessage(messages.weekDaysInBackcountryRequired))
            .required(intl.formatMessage(messages.weekDaysInBackcountryRequired)),
        [TERRAIN_FREQUENCIES_SHM_NON_AVALANCHE]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: (val: string) => SHM_ACTIVITIES.includes(val),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_SHM_SIMPLE]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: (val: string) => SHM_ACTIVITIES.includes(val),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_SHM_CHALLENGING]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: (val: string) => SHM_ACTIVITIES.includes(val),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_SHM_COMPLEX]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: (val: string) => SHM_ACTIVITIES.includes(val),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_SHM_EXTREME]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: (val: string) => SHM_ACTIVITIES.includes(val),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_ICE_CLIMBING_NON_AVALANCHE]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: ICE_CLIMBING,
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_ICE_CLIMBING_SIMPLE]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: ICE_CLIMBING,
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_ICE_CLIMBING_CHALLENGING]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: ICE_CLIMBING,
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_ICE_CLIMBING_COMPLEX]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: ICE_CLIMBING,
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_ICE_CLIMBING_EXTREME]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: ICE_CLIMBING,
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_BOS_SIMPLE]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: (val: string) => BOS_ACTIVITIES.includes(val),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_BOS_CHALLENGING]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: (val: string) => BOS_ACTIVITIES.includes(val),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_BOS_COMPLEX]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: (val: string) => BOS_ACTIVITIES.includes(val),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [TERRAIN_FREQUENCIES_BOS_EXTREME]: Yup.string().when(BACKCOUNTRY_ACTIVITIES_FIRST, {
            is: (val: string) => BOS_ACTIVITIES.includes(val),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
    })

// Step 5: EXPERIENCE
// See SignupSteps/Experience/ExperienceConstants.ts

export const createExperienceSchema = (intl: IntlShape) =>
    Yup.object().shape({
        [PERSONAL_INVOLVEMENT]: Yup.array()
            .of(Yup.string())
            .min(1, intl.formatMessage(messages.personalInvolvementRequired))
            .required(intl.formatMessage(messages.personalInvolvementRequired)),
        [PERSONAL_INVOLVEMENT_OTHER]: Yup.string().nullable(),
        [EDUCATOR_CERTIFICATION]: Yup.array()
            .of(Yup.string())
            .when(PERSONAL_INVOLVEMENT, {
                is: (val: string[]) => val?.includes(AVALANCHE_SAFETY_EDUCATOR),
                then: schema =>
                    schema
                        .min(1, intl.formatMessage(messages.selectAtLeastOne))
                        .required(intl.formatMessage(messages.selectAtLeastOne)),
            }),
        [EDUCATOR_CERTIFICATION_OTHER]: Yup.string().nullable(),
        [EDUCATOR_PAID_COURSES_MULTIPLE_TIMES_PER_SEASON]: Yup.boolean().when(PERSONAL_INVOLVEMENT, {
            is: (val: string[]) => val?.includes(AVALANCHE_SAFETY_EDUCATOR),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [CERTIFIED_MOUNTAIN_GUIDE_DAYS_WORKED_PER_SEASON]: Yup.string().when(PERSONAL_INVOLVEMENT, {
            is: (val: string[]) => val?.includes(CERTIFIED_MOUNTAIN_GUIDE),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [CERTIFIED_SKI_GUIDE_DAYS_WORKED_PER_SEASON]: Yup.string().when(PERSONAL_INVOLVEMENT, {
            is: (val: string[]) => val?.includes(CERTIFIED_SKI_GUIDE),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [ALPINE_SAR_DAYS_WORKED_PER_SEASON]: Yup.string().when(PERSONAL_INVOLVEMENT, {
            is: (val: string[]) => val?.includes(MEMBER_OF_AN_ALPINE_SEARCH_AND_RESCUE_ORGANIZATION),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [SKI_PATROL_MANAGING_RISK_SKI_AREA_DAYS_WORKED_PER_SEASON]: Yup.string().when(PERSONAL_INVOLVEMENT, {
            is: (val: string[]) => val?.includes(MEMBER_OF_SKI_PATROL_MANAGING_AVALANCHE_RISK_AT_SKI_AREA),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [AVALANCHE_PROFESSIONAL_DAYS_WORKED_PER_SEASON]: Yup.string().when(PERSONAL_INVOLVEMENT, {
            is: (val: string[]) =>
                val?.includes(
                    AVALANCHE_SAFETY_TECHNICIAN_ENGINEER_MANAGING_AVALANCHE_RISK_FOR_INFRASTRUCTURE_OR_WORKSITES
                ),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [AVALANCHE_SAFETY_EDUCATOR_DAYS_WORKED_PER_SEASON]: Yup.string().when(PERSONAL_INVOLVEMENT, {
            is: (val: string[]) => val?.includes(AVALANCHE_SAFETY_EDUCATOR),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [PUBLIC_AVALANCHE_FORECASTER_DAYS_WORKED_PER_SEASON]: Yup.string().when(PERSONAL_INVOLVEMENT, {
            is: (val: string[]) => val?.includes(PUBLIC_AVALANCHE_FORECASTER),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [AVALANCHE_RESEARCHER_DAYS_WORKED_PER_SEASON]: Yup.string().when(PERSONAL_INVOLVEMENT, {
            is: (val: string[]) => val?.includes(AVALANCHE_RESEARCHER),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
        }),
        [CERTIFIED_MOUNTAIN_GUIDE_YEAR_BECAME_PROFESSIONAL]: Yup.number().when(
            [PERSONAL_INVOLVEMENT, CERTIFIED_MOUNTAIN_GUIDE_DAYS_WORKED_PER_SEASON],
            {
                is: (personalInvolvement: string[], daysWorked: string) =>
                    personalInvolvement?.includes(CERTIFIED_MOUNTAIN_GUIDE) &&
                    checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10(daysWorked),
                then: schema =>
                    schema
                        .required(intl.formatMessage(messages.radioRequired))
                        .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
                        .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
                otherwise: schema => schema.nullable(),
            }
        ),
        [CERTIFIED_SKI_GUIDE_YEAR_BECAME_PROFESSIONAL]: Yup.number().when(
            [PERSONAL_INVOLVEMENT, CERTIFIED_SKI_GUIDE_DAYS_WORKED_PER_SEASON],
            {
                is: (personalInvolvement: string[], daysWorked: string) =>
                    personalInvolvement?.includes(CERTIFIED_SKI_GUIDE) &&
                    checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10(daysWorked),
                then: schema =>
                    schema
                        .required(intl.formatMessage(messages.radioRequired))
                        .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
                        .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
                otherwise: schema => schema.nullable(),
            }
        ),
        [ALPINE_SAR_YEAR_BECAME_PROFESSIONAL]: Yup.number().when(
            [PERSONAL_INVOLVEMENT, ALPINE_SAR_DAYS_WORKED_PER_SEASON],
            {
                is: (personalInvolvement: string[], daysWorked: string) =>
                    personalInvolvement?.includes(MEMBER_OF_AN_ALPINE_SEARCH_AND_RESCUE_ORGANIZATION) &&
                    checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10(daysWorked),
                then: schema =>
                    schema
                        .required(intl.formatMessage(messages.radioRequired))
                        .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
                        .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
                otherwise: schema => schema.nullable(),
            }
        ),
        [SKI_PATROL_MANAGING_RISK_SKI_AREA_YEAR_BECAME_PROFESSIONAL]: Yup.number().when(
            [PERSONAL_INVOLVEMENT, SKI_PATROL_MANAGING_RISK_SKI_AREA_DAYS_WORKED_PER_SEASON],
            {
                is: (personalInvolvement: string[], daysWorked: string) =>
                    personalInvolvement?.includes(MEMBER_OF_SKI_PATROL_MANAGING_AVALANCHE_RISK_AT_SKI_AREA) &&
                    checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10(daysWorked),
                then: schema =>
                    schema
                        .required(intl.formatMessage(messages.radioRequired))
                        .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
                        .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
                otherwise: schema => schema.nullable(),
            }
        ),
        [AVALANCHE_PROFESSIONAL_YEAR_BECAME_PROFESSIONAL]: Yup.number().when(
            [PERSONAL_INVOLVEMENT, AVALANCHE_PROFESSIONAL_DAYS_WORKED_PER_SEASON],
            {
                is: (personalInvolvement: string[], daysWorked: string) =>
                    personalInvolvement?.includes(
                        AVALANCHE_SAFETY_TECHNICIAN_ENGINEER_MANAGING_AVALANCHE_RISK_FOR_INFRASTRUCTURE_OR_WORKSITES
                    ) && checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10(daysWorked),
                then: schema =>
                    schema
                        .required(intl.formatMessage(messages.radioRequired))
                        .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
                        .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
                otherwise: schema => schema.nullable(),
            }
        ),
        [AVALANCHE_SAFETY_EDUCATOR_YEAR_BECAME_PROFESSIONAL]: Yup.number().when(
            [PERSONAL_INVOLVEMENT, AVALANCHE_SAFETY_EDUCATOR_DAYS_WORKED_PER_SEASON],
            {
                is: (personalInvolvement: string[], daysWorked: string) =>
                    personalInvolvement?.includes(AVALANCHE_SAFETY_EDUCATOR) &&
                    checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10(daysWorked),
                then: schema =>
                    schema
                        .required(intl.formatMessage(messages.radioRequired))
                        .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
                        .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
                otherwise: schema => schema.nullable(),
            }
        ),
        [PUBLIC_AVALANCHE_FORECASTER_YEAR_BECAME_PROFESSIONAL]: Yup.number().when(
            [PERSONAL_INVOLVEMENT, PUBLIC_AVALANCHE_FORECASTER_DAYS_WORKED_PER_SEASON],
            {
                is: (personalInvolvement: string[], daysWorked: string) =>
                    personalInvolvement?.includes(PUBLIC_AVALANCHE_FORECASTER) &&
                    checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10(daysWorked),
                then: schema =>
                    schema
                        .required(intl.formatMessage(messages.radioRequired))
                        .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
                        .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
                otherwise: schema => schema.nullable(),
            }
        ),
        [AVALANCHE_RESEARCHER_YEAR_BECAME_PROFESSIONAL]: Yup.number().when(
            [PERSONAL_INVOLVEMENT, AVALANCHE_RESEARCHER_DAYS_WORKED_PER_SEASON],
            {
                is: (personalInvolvement: string[], daysWorked: string) =>
                    personalInvolvement?.includes(AVALANCHE_RESEARCHER) &&
                    checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10(daysWorked),
                then: schema =>
                    schema
                        .required(intl.formatMessage(messages.radioRequired))
                        .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
                        .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
                otherwise: schema => schema.nullable(),
            }
        ),
        [PRACTICE_WITH_PEERS]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [PERSONAL_EXPERIENCE_IN_THE_FIELD]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [MENTORSHIP]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [FORMAL_AVALANCHE_SAFETY_COURSE]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [SELF_STUDY]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [VIDEOS_OR_CONTENT_ON_SOCIAL_MEDIA]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [ENGAGING_IN_ONLINE_COMMUNITIES]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [KNOWLEDGE_SOURCE_OTHER]: Yup.string().nullable(),
        [KNOWLEDGE_SOURCE_OTHER_TEXT]: Yup.string().nullable(),
        [HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED]: Yup.string().required(),
        [HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_OTHER]: Yup.string().nullable(),
        [HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_YEAR]: Yup.number().when(HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED, {
            is: (val: string) => isLevelOneOrHigher(val),
            then: schema =>
                schema
                    .required(intl.formatMessage(messages.radioRequired))
                    .min(MIN_YEAR, intl.formatMessage(messages.yearBecameProfessionalMin))
                    .max(MAX_YEAR, intl.formatMessage(messages.yearBecameProfessionalMax)),
            otherwise: schema => schema.nullable(),
        }),
        [TRAINING_COURSE_COUNT]: Yup.string().when(HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED, {
            is: (val: string) => isLevelOneOrHigher(val),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
            otherwise: schema => schema.nullable(),
        }),
    })

// Step 6: MOTIVATIONS
// See MotivationConstants.ts

export const createMotivationsSchema = (intl: IntlShape) =>
    Yup.object().shape({
        [NAVIGATING_THROUGH_CHALLENGING_TERRAIN]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [SPENDING_TIME_WITH_FAMILY_AND_OR_FRIENDS]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [SHARING_A_STORY_PICTURE_OR_VIDEO_ON_SOCIAL_MEDIA]: Yup.string().required(
            intl.formatMessage(messages.radioRequired)
        ),
        [BEING_IN_NATURE]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [SHARING_YOUR_OUTDOOR_SKILLS_AND_KNOWLEDGE_WITH_OTHERS]: Yup.string().required(
            intl.formatMessage(messages.radioRequired)
        ),
        [EXPERIENCING_THE_THRILL_EXHAILERATION_AND_RUSH]: Yup.string().required(
            intl.formatMessage(messages.radioRequired)
        ),
        [FEELING_CAREFREE_AND_OR_RELAXED]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [BEING_AWAY_FROM_CROWDS]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [EXERCISING_AND_WORKING_ON_YOUR_PHYSICAL_FITNESS]: Yup.string().required(
            intl.formatMessage(messages.radioRequired)
        ),
        [PRACTICING_IMPROVING_YOUR_RISK_MGMT_SKILLS]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [EXPERIENCING_RISK]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [ENJOYING_POWDER_SNOW_CONDITIONS_PERMITTING]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [CHALLENGING_IMPROVING_YOUR_TECHNICAL_SKILLS]: Yup.string().required(
            intl.formatMessage(messages.radioRequired)
        ),
        [REACHING_A_SUMMIT_OR_SIMILAR_TRIP_OBJECTIVE]: Yup.string().required(
            intl.formatMessage(messages.radioRequired)
        ),
        [COMPLETING_CLASSIC_WELL_KNOWN_RESPECTED_TRIPS]: Yup.string().required(
            intl.formatMessage(messages.radioRequired)
        ),
        [EXPLORING_NEW_OR_DIFFERENT_PLACES]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [EXPERIENCING_REMOTENESS]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [DECISION_MAKING_CONTRIBUTION]: Yup.string().required(intl.formatMessage(messages.radioRequired)),
        [INFORMATION_SOURCES_CONSULTED]: Yup.array().of(Yup.string()).min(1, undefined).required(),
        [INFORMATION_SOURCES_CONSULTED_OTHER]: Yup.string().nullable(),
        [AVALANCHE_FORECAST_CHECKED_FREQUENCY]: Yup.string().when(INFORMATION_SOURCES_CONSULTED, {
            is: (val: string[]) => val?.includes(REGIONAL_AVALANCHE_FORECAST),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
            otherwise: schema => schema.nullable(),
        }),
        [AVALANCHE_FORECAST_USAGE]: Yup.string().when(INFORMATION_SOURCES_CONSULTED, {
            is: (val: string[]) => val?.includes(REGIONAL_AVALANCHE_FORECAST),
            then: schema => schema.required(intl.formatMessage(messages.radioRequired)),
            otherwise: schema => schema.nullable(),
        }),
        [SAFETY_EQUIPMENT_TYPICALLY_USED]: Yup.array().of(Yup.string()).min(1, undefined).required(),
        [TRACK_WITH_GPS]: Yup.boolean()
            .oneOf([true, false], intl.formatMessage(messages.radioRequired))
            .required(intl.formatMessage(messages.radioRequired)),
        [GPS_TRACKING_PLATFORMS]: Yup.array()
            .of(Yup.string())
            .when(TRACK_WITH_GPS, {
                is: true,
                then: schema => schema.min(1, undefined).required(),
                otherwise: schema => schema.nullable(),
            }),
        [GPS_TRACKING_PLATFORM_OTHER]: Yup.string().nullable(),
    })

// Combined schema for the entire form
export const createUserResearchPanelSchema = (intl: IntlShape) => {
    const consentSchema = createConsentSchema(intl)
    const dataPrivacySchema = createDataPrivacySchema(intl)
    const backgroundSchema = createBackgroundSchema(intl)
    const backcountryActivitiesSchema = createBackcountryActivitiesSchema(intl)
    const experienceSchema = createExperienceSchema(intl)
    const motivationsSchema = createMotivationsSchema(intl)

    return Yup.object().shape({
        ...consentSchema.fields,
        ...dataPrivacySchema.fields,
        ...backgroundSchema.fields,
        ...backcountryActivitiesSchema.fields,
        ...experienceSchema.fields,
        ...motivationsSchema.fields,
    })
}

// Types for each step and the complete form
export type ConsentFormValues = ReturnType<typeof createConsentSchema>['__outputType']
export type PersonalInfoFormValues = ReturnType<typeof createDataPrivacySchema>['__outputType']
export type BackgroundFormValues = ReturnType<typeof createBackgroundSchema>['__outputType']
export type BackcountryActivitiesFormValues = ReturnType<typeof createBackcountryActivitiesSchema>['__outputType']
export type ExperienceFormValues = ReturnType<typeof createExperienceSchema>['__outputType']
export type MotivationsFormValues = ReturnType<typeof createMotivationsSchema>['__outputType']
export type UserResearchPanelFormValues = ReturnType<typeof createUserResearchPanelSchema>['__outputType']
