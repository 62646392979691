import { defineMessages } from 'react-intl'

import {
    ENGAGING_IN_ONLINE_COMMUNITIES,
    FORMAL_AVALANCHE_SAFETY_COURSE,
    MENTORSHIP,
    KNOWLEDGE_SOURCE_OTHER,
    PERSONAL_EXPERIENCE_IN_THE_FIELD,
    SELF_STUDY,
    PRACTICE_WITH_PEERS,
    VIDEOS_OR_CONTENT_ON_SOCIAL_MEDIA,
} from './ExperienceConstants'

export const KNOWLEDGE_SOURCE_KEYS = {
    [PRACTICE_WITH_PEERS]: {
        messageKey: 'practice_with_peers_at_a_similar_experience_level',
    },
    [PERSONAL_EXPERIENCE_IN_THE_FIELD]: {
        messageKey: 'personal_experience_in_the_field',
    },
    [MENTORSHIP]: {
        messageKey: 'mentorship_from_a_more_experienced_person',
    },
    [FORMAL_AVALANCHE_SAFETY_COURSE]: {
        messageKey: 'formal_avalanche_safety_course_from_an_avalanche_education_provider',
    },
    [SELF_STUDY]: {
        messageKey: 'self_study_of_books_and_online_tutorials',
    },
    [VIDEOS_OR_CONTENT_ON_SOCIAL_MEDIA]: {
        messageKey: 'videos_or_content_on_social_media',
    },
    [ENGAGING_IN_ONLINE_COMMUNITIES]: {
        messageKey: 'engaging_in_online_communities',
    },
    [KNOWLEDGE_SOURCE_OTHER]: {
        messageKey: 'other',
    },
}

export const knowledgeMessages = defineMessages({
    practice_with_peers_at_a_similar_experience_level: {
        description: 'User Research Panel',
        defaultMessage: 'Practice with peers at a similar experience level',
    },
    personal_experience_in_the_field: {
        description: 'User Research Panel',
        defaultMessage: 'Personal experience in the field',
    },
    mentorship_from_a_more_experienced_person: {
        description: 'User Research Panel',
        defaultMessage: 'Mentorship from a more experienced person',
    },
    formal_avalanche_safety_course_from_an_avalanche_education_provider: {
        description: 'User Research Panel',
        defaultMessage: 'Formal avalanche safety course from an avalanche education provider',
    },
    self_study_of_books_and_online_tutorials: {
        description: 'User Research Panel',
        defaultMessage: 'Self-study of books and online tutorials',
    },
    videos_or_content_on_social_media: {
        description: 'User Research Panel',
        defaultMessage: 'Videos or content on social media',
    },
    engaging_in_online_communities: {
        description: 'User Research Panel',
        defaultMessage:
            'Engaging in online communities (e.g., asking questions or reading comments in Facebook groups)',
    },
    other: {
        description: 'User Research Panel',
        defaultMessage: 'Importance of other knowledge sources',
    },
    otherPlaceholder: {
        description: 'User Research Panel',
        defaultMessage: 'Please specify other knowledge sources',
    },
})
