import { FormHelperText, MenuItem } from '@mui/material'
import { Select } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { UserResearchPanelSignupStore, useHandleChange } from 'stores/UserResearchPanelSignupStore'
import { YEAR_OPTIONS } from '../validationSchema'
import { UserResearchPanelFormValues } from '../validationSchema'

type YearSelectProps = {
    dataKey: keyof UserResearchPanelFormValues
}

export const YearSelect = ({ dataKey }: YearSelectProps) => {
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()

    return (
        <>
            <Select
                sx={styles.yearInput}
                value={String(formData[dataKey]) || undefined}
                onChange={e => {
                    handleChange(dataKey, e.target.value as string)
                }}
                error={touched[dataKey] && !!errors[dataKey]}
                displayEmpty
            >
                <MenuItem value={undefined}>
                    <FormattedMessage
                        description="User Research Panel year dropdown placeholder"
                        defaultMessage="Select year"
                    />
                </MenuItem>
                {YEAR_OPTIONS.map(year => (
                    <MenuItem key={year} value={year}>
                        {year}
                    </MenuItem>
                ))}
            </Select>
            {touched[dataKey] && errors[dataKey] && <FormHelperText error>{errors[dataKey]}</FormHelperText>}
        </>
    )
}

const styles = {
    yearInput: {
        width: 300,
        marginTop: 1,
    },
}
