import { FormattedMessage } from 'react-intl'

import { UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import { KNOWLEDGE_SOURCE_KEYS } from './Knowledge'
import { knowledgeMessages } from './Knowledge'
import { ImportanceRanking } from 'components/UserResearchPanel/CommonInputs/ImportanceRanking'
import { KNOWLEDGE_SOURCE_OTHER } from './ExperienceConstants'
import { QuestionGroup } from 'components/UserResearchPanel/CommonInputs/QuestionGroup'

export const KnowledgeSource = ({
    knowledgeSourceField,
}: {
    knowledgeSourceField: keyof typeof KNOWLEDGE_SOURCE_KEYS
}) => {
    const { touched, errors } = UserResearchPanelSignupStore()

    return (
        <QuestionGroup
            question={
                <FormattedMessage
                    {...knowledgeMessages[
                        KNOWLEDGE_SOURCE_KEYS[knowledgeSourceField].messageKey as keyof typeof knowledgeMessages
                    ]}
                />
            }
            required={knowledgeSourceField !== KNOWLEDGE_SOURCE_OTHER}
            error={touched[knowledgeSourceField] && !!errors[knowledgeSourceField]}
            errorMessage={errors[knowledgeSourceField as keyof typeof errors]}
        >
            <ImportanceRanking schemaField={knowledgeSourceField} />
        </QuestionGroup>
    )
}
