import { Box, FormControl, FormHelperText, FormControlLabel, Checkbox, Radio, RadioGroup } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

import { messages as personalInvolvementMessages, PERSONAL_INVOLVEMENT_OPTIONS } from './PersonalInvolvement'
import { useHandleChange, UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import {
    EDUCATOR_CERTIFICATION,
    EDUCATOR_CERTIFICATION_OTHER,
    EDUCATOR_PAID_COURSES_MULTIPLE_TIMES_PER_SEASON,
    PERSONAL_INVOLVEMENT,
    PERSONAL_INVOLVEMENT_OTHER,
    PUBLIC_AVALANCHE_FORECASTER,
    AVALANCHE_SAFETY_TECHNICIAN_ENGINEER_MANAGING_AVALANCHE_RISK_FOR_INFRASTRUCTURE_OR_WORKSITES,
    CERTIFIED_MOUNTAIN_GUIDE,
    CERTIFIED_SKI_GUIDE,
    MEMBER_OF_AN_ALPINE_SEARCH_AND_RESCUE_ORGANIZATION,
    MEMBER_OF_SKI_PATROL_MANAGING_AVALANCHE_RISK_AT_SKI_AREA,
    OTHER,
    AVALANCHE_RESEARCHER,
    AVALANCHE_SAFETY_EDUCATOR,
} from './ExperienceConstants'
import { EDUCATOR_CERTIFICATION_OPTIONS, messages as educatorMessages } from './Educator'
import { ProfessionalQuestions } from './ProfessionalQuestions'
import { KnowledgeSources } from './KnowledgeSources'
import { TrainingCourses } from './TrainingCourses'
import { TextInput } from 'components/UserResearchPanel/CommonInputs/TextInput'
import { commonMessages } from 'components/UserResearchPanel/CommonInputs/CommonMessages'
import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'
import { Heading } from 'components/UserResearchPanel/CommonInputs/Heading'

export const Experience = () => {
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()
    const { formatMessage } = useIntl()

    const handlePersonalInvolvementChange = (activity: string) => {
        const newValue = Array.isArray(formData[PERSONAL_INVOLVEMENT])
            ? formData[PERSONAL_INVOLVEMENT].includes(activity)
                ? formData[PERSONAL_INVOLVEMENT].filter(item => item !== activity)
                : [...(formData[PERSONAL_INVOLVEMENT] as string[]), activity]
            : [activity]

        handleChange(PERSONAL_INVOLVEMENT, newValue)
    }

    const handleEducatorCertificationChange = (activity: string) => {
        const newValue = Array.isArray(formData[EDUCATOR_CERTIFICATION])
            ? formData[EDUCATOR_CERTIFICATION].includes(activity)
                ? formData[EDUCATOR_CERTIFICATION].filter(item => item !== activity)
                : [...(formData[EDUCATOR_CERTIFICATION] as string[]), activity]
            : [activity]

        handleChange(EDUCATOR_CERTIFICATION, newValue)
    }

    const showPersonalInvolvementOtherField =
        Array.isArray(formData[PERSONAL_INVOLVEMENT]) && formData[PERSONAL_INVOLVEMENT].includes(OTHER)
    const showEducatorQuestions =
        Array.isArray(formData[PERSONAL_INVOLVEMENT]) &&
        formData[PERSONAL_INVOLVEMENT].includes(AVALANCHE_SAFETY_EDUCATOR)
    const showEducatorCertificationOtherField =
        Array.isArray(formData[EDUCATOR_CERTIFICATION]) && formData[EDUCATOR_CERTIFICATION].includes(OTHER)
    const showMountainGuideQuestions =
        Array.isArray(formData[PERSONAL_INVOLVEMENT]) &&
        formData[PERSONAL_INVOLVEMENT].includes(CERTIFIED_MOUNTAIN_GUIDE)
    const showSkiGuideQuestions =
        Array.isArray(formData[PERSONAL_INVOLVEMENT]) && formData[PERSONAL_INVOLVEMENT].includes(CERTIFIED_SKI_GUIDE)
    const showAlpineSearchAndRescueQuestions =
        Array.isArray(formData[PERSONAL_INVOLVEMENT]) &&
        formData[PERSONAL_INVOLVEMENT].includes(MEMBER_OF_AN_ALPINE_SEARCH_AND_RESCUE_ORGANIZATION)
    const showSkiPatrolQuestions =
        Array.isArray(formData[PERSONAL_INVOLVEMENT]) &&
        formData[PERSONAL_INVOLVEMENT].includes(MEMBER_OF_SKI_PATROL_MANAGING_AVALANCHE_RISK_AT_SKI_AREA)
    const showSafetyEngineerQuestions =
        Array.isArray(formData[PERSONAL_INVOLVEMENT]) &&
        formData[PERSONAL_INVOLVEMENT].includes(
            AVALANCHE_SAFETY_TECHNICIAN_ENGINEER_MANAGING_AVALANCHE_RISK_FOR_INFRASTRUCTURE_OR_WORKSITES
        )
    const showPublicAvalancheForecasterQuestions =
        Array.isArray(formData[PERSONAL_INVOLVEMENT]) &&
        formData[PERSONAL_INVOLVEMENT].includes(PUBLIC_AVALANCHE_FORECASTER)
    const showAvalancheResearcherQuestions =
        Array.isArray(formData[PERSONAL_INVOLVEMENT]) && formData[PERSONAL_INVOLVEMENT].includes(AVALANCHE_RESEARCHER)

    return (
        <Box>
            <Heading>
                <FormattedMessage description="User Research Panel" defaultMessage="Experience" />
            </Heading>
            <FormControl
                required
                error={touched[PERSONAL_INVOLVEMENT] && !!errors[PERSONAL_INVOLVEMENT]}
                sx={styles.formControl}
            >
                <QuestionText required>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="How are you personally involved in avalanche risk management?"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                </FormHelperText>
                <Box sx={styles.flex}>
                    {PERSONAL_INVOLVEMENT_OPTIONS.map(option => (
                        <FormControlLabel
                            key={option.name}
                            control={
                                <Checkbox
                                    checked={
                                        Array.isArray(formData[PERSONAL_INVOLVEMENT])
                                            ? formData[PERSONAL_INVOLVEMENT].includes(option.name)
                                            : false
                                    }
                                    onChange={() => handlePersonalInvolvementChange(option.name)}
                                    inputProps={{
                                        'aria-invalid': touched[PERSONAL_INVOLVEMENT] && !!errors[PERSONAL_INVOLVEMENT],
                                    }}
                                />
                            }
                            label={<FormattedMessage {...personalInvolvementMessages[option.name]} />}
                        />
                    ))}
                </Box>
                {showPersonalInvolvementOtherField && (
                    <TextInput
                        dataKey={PERSONAL_INVOLVEMENT_OTHER}
                        placeholder={formatMessage(personalInvolvementMessages.otherPlaceholder)}
                        sx={{ mt: 2 }}
                    />
                )}
            </FormControl>
            {showEducatorQuestions && (
                <>
                    <FormControl
                        sx={styles.formControl}
                        error={touched[EDUCATOR_CERTIFICATION] && !!errors[EDUCATOR_CERTIFICATION]}
                    >
                        <QuestionText required>
                            <FormattedMessage
                                description="User Research Panel"
                                defaultMessage="Do you have any certification as an avalanche educator?"
                            />
                        </QuestionText>
                        <FormHelperText>
                            <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                        </FormHelperText>
                        <Box sx={styles.flex}>
                            {EDUCATOR_CERTIFICATION_OPTIONS.map(option => (
                                <FormControlLabel
                                    key={option}
                                    control={
                                        <Checkbox
                                            checked={
                                                Array.isArray(formData[EDUCATOR_CERTIFICATION])
                                                    ? formData[EDUCATOR_CERTIFICATION].includes(option)
                                                    : false
                                            }
                                            inputProps={{
                                                'aria-invalid':
                                                    touched[EDUCATOR_CERTIFICATION] && !!errors[EDUCATOR_CERTIFICATION],
                                            }}
                                            onChange={() => handleEducatorCertificationChange(option)}
                                        />
                                    }
                                    label={<FormattedMessage {...educatorMessages[option]} />}
                                />
                            ))}
                        </Box>
                    </FormControl>
                    {showEducatorCertificationOtherField && (
                        <TextInput
                            dataKey={EDUCATOR_CERTIFICATION_OTHER}
                            placeholder={formatMessage(educatorMessages.otherEducatorCertification)}
                            sx={{ mt: 2 }}
                        />
                    )}
                    <FormControl
                        sx={styles.formControl}
                        error={
                            touched[EDUCATOR_PAID_COURSES_MULTIPLE_TIMES_PER_SEASON] &&
                            !!errors[EDUCATOR_PAID_COURSES_MULTIPLE_TIMES_PER_SEASON]
                        }
                    >
                        <QuestionText required>
                            <FormattedMessage
                                description="User Research Panel"
                                defaultMessage="Do you teach avalanche safety courses to paying students multiple times a winter?"
                            />
                        </QuestionText>
                        <FormHelperText>
                            <FormattedMessage {...commonMessages.selectOneOption} />
                        </FormHelperText>
                        <RadioGroup
                            value={formData[EDUCATOR_PAID_COURSES_MULTIPLE_TIMES_PER_SEASON]}
                            onChange={e => {
                                handleChange(EDUCATOR_PAID_COURSES_MULTIPLE_TIMES_PER_SEASON, e.target.value)
                            }}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label={<FormattedMessage description="User Research Panel" defaultMessage="Yes" />}
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label={<FormattedMessage description="User Research Panel" defaultMessage="No" />}
                            />
                        </RadioGroup>
                    </FormControl>
                    <ProfessionalQuestions professionalRoleKey={AVALANCHE_SAFETY_EDUCATOR} />
                </>
            )}
            {showMountainGuideQuestions && <ProfessionalQuestions professionalRoleKey={CERTIFIED_MOUNTAIN_GUIDE} />}
            {showSkiGuideQuestions && <ProfessionalQuestions professionalRoleKey={CERTIFIED_SKI_GUIDE} />}
            {showAlpineSearchAndRescueQuestions && (
                <ProfessionalQuestions professionalRoleKey={MEMBER_OF_AN_ALPINE_SEARCH_AND_RESCUE_ORGANIZATION} />
            )}
            {showSkiPatrolQuestions && (
                <ProfessionalQuestions professionalRoleKey={MEMBER_OF_SKI_PATROL_MANAGING_AVALANCHE_RISK_AT_SKI_AREA} />
            )}
            {showSafetyEngineerQuestions && (
                <ProfessionalQuestions
                    professionalRoleKey={
                        AVALANCHE_SAFETY_TECHNICIAN_ENGINEER_MANAGING_AVALANCHE_RISK_FOR_INFRASTRUCTURE_OR_WORKSITES
                    }
                />
            )}
            {showPublicAvalancheForecasterQuestions && (
                <ProfessionalQuestions professionalRoleKey={PUBLIC_AVALANCHE_FORECASTER} />
            )}
            {showAvalancheResearcherQuestions && <ProfessionalQuestions professionalRoleKey={AVALANCHE_RESEARCHER} />}
            <KnowledgeSources />
            <TrainingCourses />
        </Box>
    )
}

const styles = {
    flex: {
        display: 'flex' as const,
        gap: '1px',
        flexDirection: 'column' as const,
        flexWrap: 'wrap' as const,
    },
    formControl: {
        marginTop: 4,
        width: '100%',
    },
}
