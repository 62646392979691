import { defineMessages } from 'react-intl'

export const CAA_BASIC_AVALANCHE_EDUCATOR = 'caaBasic'
export const CAA_ADVANCED_AVALANCHE_EDUCATOR = 'caaAdvanced'
export const CAA_AVALANCHE_PROFESSIONAL = 'caaProfessional'
export const AIARE_QUALIFIED_INSTRUCTOR = 'aiareInstructor'
export const AIARE_QUALIFIED_COURSE_LEAD = 'aiareCourseLead'
export const OTHER = 'other'

export const EDUCATOR_CERTIFICATION_OPTIONS = [
    CAA_BASIC_AVALANCHE_EDUCATOR,
    CAA_ADVANCED_AVALANCHE_EDUCATOR,
    CAA_AVALANCHE_PROFESSIONAL,
    AIARE_QUALIFIED_INSTRUCTOR,
    AIARE_QUALIFIED_COURSE_LEAD,
    OTHER,
] as const

export const messages = defineMessages({
    caaBasic: {
        id: 'UserResearchPanel.Educator.caaBasic',
        defaultMessage: 'CAA Basic Avalanche Educator',
    },
    caaAdvanced: {
        id: 'UserResearchPanel.Educator.caaAdvanced',
        defaultMessage: 'CAA Advanced Avalanche Educator',
    },
    caaProfessional: {
        id: 'UserResearchPanel.Educator.caaProfessional',
        defaultMessage: 'CAA Avalanche Professional',
    },
    aiareInstructor: {
        id: 'UserResearchPanel.Educator.aiareInstructor',
        defaultMessage: 'AIARE qualified instructor',
    },
    aiareCourseLead: {
        id: 'UserResearchPanel.Educator.aiareCourseLead',
        defaultMessage: 'AIARE qualified course lead',
    },
    other: {
        id: 'UserResearchPanel.Educator.other',
        defaultMessage: 'Other',
    },
    otherPlaceholder: {
        id: 'UserResearchPanel.Educator.otherPlaceholder',
        defaultMessage: 'Please specify other activities',
    },
    otherEducatorCertification: {
        id: 'UserResearchPanel.Educator.otherEducatorCertification',
        defaultMessage: 'Please specify other educator certification',
    },
})
