import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl'

import { useHandleBlur, useHandleChange, UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import { COUNTRY } from '../validationSchema'

const messages = defineMessages({
    chooseCountry: {
        id: 'UserResearchPanel.CountrySelect.chooseCountry',
        defaultMessage: 'Choose a country',
    },
})

export default function CountrySelect() {
    const { formatMessage } = useIntl()
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()
    const handleBlur = useHandleBlur()
    const value = formData.country ? countries.find(c => c.code === formData.country) : null

    return (
        <Autocomplete
            id="country-select-demo"
            sx={styles.autocomplete}
            options={countries}
            autoHighlight
            value={value}
            onBlur={() => handleBlur(COUNTRY, formData[COUNTRY] as string)}
            onChange={(_, newValue) => handleChange(COUNTRY, newValue?.code || '')}
            getOptionLabel={option => formatMessage(option.label)}
            renderOption={(props, option) => (
                <Box key={option.code} component="li" sx={styles.renderOption} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                    />
                    {formatMessage(option.label)}
                </Box>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    label={formatMessage(messages.chooseCountry)}
                    error={touched[COUNTRY] && !!errors[COUNTRY]}
                    helperText={touched[COUNTRY] && errors[COUNTRY]}
                />
            )}
        />
    )
}

const styles = {
    autocomplete: {
        width: 300,
    },
    renderOption: {
        '& > img': {
            mr: 2,
            flexShrink: 0,
        },
    },
}

// Helpers and constants
const countryMessages = defineMessages({
    CA: { id: 'country.CA', defaultMessage: 'Canada' },
    US: { id: 'country.US', defaultMessage: 'United States' },
    AD: { id: 'country.AD', defaultMessage: 'Andorra' },
    AE: { id: 'country.AE', defaultMessage: 'United Arab Emirates' },
    AF: { id: 'country.AF', defaultMessage: 'Afghanistan' },
    AG: { id: 'country.AG', defaultMessage: 'Antigua and Barbuda' },
    AI: { id: 'country.AI', defaultMessage: 'Anguilla' },
    AL: { id: 'country.AL', defaultMessage: 'Albania' },
    AM: { id: 'country.AM', defaultMessage: 'Armenia' },
    AO: { id: 'country.AO', defaultMessage: 'Angola' },
    AQ: { id: 'country.AQ', defaultMessage: 'Antarctica' },
    AR: { id: 'country.AR', defaultMessage: 'Argentina' },
    AS: { id: 'country.AS', defaultMessage: 'American Samoa' },
    AT: { id: 'country.AT', defaultMessage: 'Austria' },
    AU: { id: 'country.AU', defaultMessage: 'Australia' },
    AW: { id: 'country.AW', defaultMessage: 'Aruba' },
    AX: { id: 'country.AX', defaultMessage: 'Alland Islands' },
    AZ: { id: 'country.AZ', defaultMessage: 'Azerbaijan' },
    BA: { id: 'country.BA', defaultMessage: 'Bosnia and Herzegovina' },
    BB: { id: 'country.BB', defaultMessage: 'Barbados' },
    BD: { id: 'country.BD', defaultMessage: 'Bangladesh' },
    BE: { id: 'country.BE', defaultMessage: 'Belgium' },
    BF: { id: 'country.BF', defaultMessage: 'Burkina Faso' },
    BG: { id: 'country.BG', defaultMessage: 'Bulgaria' },
    BH: { id: 'country.BH', defaultMessage: 'Bahrain' },
    BI: { id: 'country.BI', defaultMessage: 'Burundi' },
    BJ: { id: 'country.BJ', defaultMessage: 'Benin' },
    BL: { id: 'country.BL', defaultMessage: 'Saint Barthelemy' },
    BM: { id: 'country.BM', defaultMessage: 'Bermuda' },
    BN: { id: 'country.BN', defaultMessage: 'Brunei Darussalam' },
    BO: { id: 'country.BO', defaultMessage: 'Bolivia' },
    BR: { id: 'country.BR', defaultMessage: 'Brazil' },
    BS: { id: 'country.BS', defaultMessage: 'Bahamas' },
    BT: { id: 'country.BT', defaultMessage: 'Bhutan' },
    BV: { id: 'country.BV', defaultMessage: 'Bouvet Island' },
    BW: { id: 'country.BW', defaultMessage: 'Botswana' },
    BY: { id: 'country.BY', defaultMessage: 'Belarus' },
    BZ: { id: 'country.BZ', defaultMessage: 'Belize' },
    CC: { id: 'country.CC', defaultMessage: 'Cocos (Keeling) Islands' },
    CD: { id: 'country.CD', defaultMessage: 'Congo, Democratic Republic of the' },
    CF: { id: 'country.CF', defaultMessage: 'Central African Republic' },
    CG: { id: 'country.CG', defaultMessage: 'Congo, Republic of the' },
    CH: { id: 'country.CH', defaultMessage: 'Switzerland' },
    CI: { id: 'country.CI', defaultMessage: "Cote d'Ivoire" },
    CK: { id: 'country.CK', defaultMessage: 'Cook Islands' },
    CL: { id: 'country.CL', defaultMessage: 'Chile' },
    CM: { id: 'country.CM', defaultMessage: 'Cameroon' },
    CN: { id: 'country.CN', defaultMessage: 'China' },
    CO: { id: 'country.CO', defaultMessage: 'Colombia' },
    CR: { id: 'country.CR', defaultMessage: 'Costa Rica' },
    CU: { id: 'country.CU', defaultMessage: 'Cuba' },
    CV: { id: 'country.CV', defaultMessage: 'Cape Verde' },
    CW: { id: 'country.CW', defaultMessage: 'Curacao' },
    CX: { id: 'country.CX', defaultMessage: 'Christmas Island' },
    CY: { id: 'country.CY', defaultMessage: 'Cyprus' },
    CZ: { id: 'country.CZ', defaultMessage: 'Czech Republic' },
    DE: { id: 'country.DE', defaultMessage: 'Germany' },
    DJ: { id: 'country.DJ', defaultMessage: 'Djibouti' },
    DK: { id: 'country.DK', defaultMessage: 'Denmark' },
    DM: { id: 'country.DM', defaultMessage: 'Dominica' },
    DO: { id: 'country.DO', defaultMessage: 'Dominican Republic' },
    DZ: { id: 'country.DZ', defaultMessage: 'Algeria' },
    EC: { id: 'country.EC', defaultMessage: 'Ecuador' },
    EE: { id: 'country.EE', defaultMessage: 'Estonia' },
    EG: { id: 'country.EG', defaultMessage: 'Egypt' },
    EH: { id: 'country.EH', defaultMessage: 'Western Sahara' },
    ER: { id: 'country.ER', defaultMessage: 'Eritrea' },
    ES: { id: 'country.ES', defaultMessage: 'Spain' },
    ET: { id: 'country.ET', defaultMessage: 'Ethiopia' },
    FI: { id: 'country.FI', defaultMessage: 'Finland' },
    FJ: { id: 'country.FJ', defaultMessage: 'Fiji' },
    FK: { id: 'country.FK', defaultMessage: 'Falkland Islands (Malvinas)' },
    FM: { id: 'country.FM', defaultMessage: 'Micronesia, Federated States of' },
    FO: { id: 'country.FO', defaultMessage: 'Faroe Islands' },
    FR: { id: 'country.FR', defaultMessage: 'France' },
    GA: { id: 'country.GA', defaultMessage: 'Gabon' },
    GB: { id: 'country.GB', defaultMessage: 'United Kingdom' },
    GD: { id: 'country.GD', defaultMessage: 'Grenada' },
    GE: { id: 'country.GE', defaultMessage: 'Georgia' },
    GF: { id: 'country.GF', defaultMessage: 'French Guiana' },
    GG: { id: 'country.GG', defaultMessage: 'Guernsey' },
    GH: { id: 'country.GH', defaultMessage: 'Ghana' },
    GI: { id: 'country.GI', defaultMessage: 'Gibraltar' },
    GL: { id: 'country.GL', defaultMessage: 'Greenland' },
    GM: { id: 'country.GM', defaultMessage: 'Gambia' },
    GN: { id: 'country.GN', defaultMessage: 'Guinea' },
    GP: { id: 'country.GP', defaultMessage: 'Guadeloupe' },
    GQ: { id: 'country.GQ', defaultMessage: 'Equatorial Guinea' },
    GR: { id: 'country.GR', defaultMessage: 'Greece' },
    GS: { id: 'country.GS', defaultMessage: 'South Georgia and the South Sandwich Islands' },
    GT: { id: 'country.GT', defaultMessage: 'Guatemala' },
    GU: { id: 'country.GU', defaultMessage: 'Guam' },
    GW: { id: 'country.GW', defaultMessage: 'Guinea-Bissau' },
    GY: { id: 'country.GY', defaultMessage: 'Guyana' },
    HK: { id: 'country.HK', defaultMessage: 'Hong Kong' },
    HM: { id: 'country.HM', defaultMessage: 'Heard Island and McDonald Islands' },
    HN: { id: 'country.HN', defaultMessage: 'Honduras' },
    HR: { id: 'country.HR', defaultMessage: 'Croatia' },
    HT: { id: 'country.HT', defaultMessage: 'Haiti' },
    HU: { id: 'country.HU', defaultMessage: 'Hungary' },
    ID: { id: 'country.ID', defaultMessage: 'Indonesia' },
    IE: { id: 'country.IE', defaultMessage: 'Ireland' },
    IL: { id: 'country.IL', defaultMessage: 'Israel' },
    IM: { id: 'country.IM', defaultMessage: 'Isle of Man' },
    IN: { id: 'country.IN', defaultMessage: 'India' },
    IO: { id: 'country.IO', defaultMessage: 'British Indian Ocean Territory' },
    IQ: { id: 'country.IQ', defaultMessage: 'Iraq' },
    IR: { id: 'country.IR', defaultMessage: 'Iran, Islamic Republic of' },
    IS: { id: 'country.IS', defaultMessage: 'Iceland' },
    IT: { id: 'country.IT', defaultMessage: 'Italy' },
    JE: { id: 'country.JE', defaultMessage: 'Jersey' },
    JM: { id: 'country.JM', defaultMessage: 'Jamaica' },
    JO: { id: 'country.JO', defaultMessage: 'Jordan' },
    JP: { id: 'country.JP', defaultMessage: 'Japan' },
    KE: { id: 'country.KE', defaultMessage: 'Kenya' },
    KG: { id: 'country.KG', defaultMessage: 'Kyrgyzstan' },
    KH: { id: 'country.KH', defaultMessage: 'Cambodia' },
    KI: { id: 'country.KI', defaultMessage: 'Kiribati' },
    KM: { id: 'country.KM', defaultMessage: 'Comoros' },
    KN: { id: 'country.KN', defaultMessage: 'Saint Kitts and Nevis' },
    KP: { id: 'country.KP', defaultMessage: "Korea, Democratic People's Republic of" },
    KR: { id: 'country.KR', defaultMessage: 'Korea, Republic of' },
    KW: { id: 'country.KW', defaultMessage: 'Kuwait' },
    KY: { id: 'country.KY', defaultMessage: 'Cayman Islands' },
    KZ: { id: 'country.KZ', defaultMessage: 'Kazakhstan' },
    LA: { id: 'country.LA', defaultMessage: "Lao People's Democratic Republic" },
    LB: { id: 'country.LB', defaultMessage: 'Lebanon' },
    LC: { id: 'country.LC', defaultMessage: 'Saint Lucia' },
    LI: { id: 'country.LI', defaultMessage: 'Liechtenstein' },
    LK: { id: 'country.LK', defaultMessage: 'Sri Lanka' },
    LR: { id: 'country.LR', defaultMessage: 'Liberia' },
    LS: { id: 'country.LS', defaultMessage: 'Lesotho' },
    LT: { id: 'country.LT', defaultMessage: 'Lithuania' },
    LU: { id: 'country.LU', defaultMessage: 'Luxembourg' },
    LV: { id: 'country.LV', defaultMessage: 'Latvia' },
    LY: { id: 'country.LY', defaultMessage: 'Libya' },
    MA: { id: 'country.MA', defaultMessage: 'Morocco' },
    MC: { id: 'country.MC', defaultMessage: 'Monaco' },
    MD: { id: 'country.MD', defaultMessage: 'Moldova, Republic of' },
    ME: { id: 'country.ME', defaultMessage: 'Montenegro' },
    MF: { id: 'country.MF', defaultMessage: 'Saint Martin (French part)' },
    MG: { id: 'country.MG', defaultMessage: 'Madagascar' },
    MH: { id: 'country.MH', defaultMessage: 'Marshall Islands' },
    MK: { id: 'country.MK', defaultMessage: 'Macedonia, the Former Yugoslav Republic of' },
    ML: { id: 'country.ML', defaultMessage: 'Mali' },
    MM: { id: 'country.MM', defaultMessage: 'Myanmar' },
    MN: { id: 'country.MN', defaultMessage: 'Mongolia' },
    MO: { id: 'country.MO', defaultMessage: 'Macao' },
    MP: { id: 'country.MP', defaultMessage: 'Northern Mariana Islands' },
    MQ: { id: 'country.MQ', defaultMessage: 'Martinique' },
    MR: { id: 'country.MR', defaultMessage: 'Mauritania' },
    MS: { id: 'country.MS', defaultMessage: 'Montserrat' },
    MT: { id: 'country.MT', defaultMessage: 'Malta' },
    MU: { id: 'country.MU', defaultMessage: 'Mauritius' },
    MV: { id: 'country.MV', defaultMessage: 'Maldives' },
    MW: { id: 'country.MW', defaultMessage: 'Malawi' },
    MX: { id: 'country.MX', defaultMessage: 'Mexico' },
    MY: { id: 'country.MY', defaultMessage: 'Malaysia' },
    MZ: { id: 'country.MZ', defaultMessage: 'Mozambique' },
    NA: { id: 'country.NA', defaultMessage: 'Namibia' },
    NC: { id: 'country.NC', defaultMessage: 'New Caledonia' },
    NE: { id: 'country.NE', defaultMessage: 'Niger' },
    NF: { id: 'country.NF', defaultMessage: 'Norfolk Island' },
    NG: { id: 'country.NG', defaultMessage: 'Nigeria' },
    NI: { id: 'country.NI', defaultMessage: 'Nicaragua' },
    NL: { id: 'country.NL', defaultMessage: 'Netherlands' },
    NO: { id: 'country.NO', defaultMessage: 'Norway' },
    NP: { id: 'country.NP', defaultMessage: 'Nepal' },
    NR: { id: 'country.NR', defaultMessage: 'Nauru' },
    NU: { id: 'country.NU', defaultMessage: 'Niue' },
    NZ: { id: 'country.NZ', defaultMessage: 'New Zealand' },
    OM: { id: 'country.OM', defaultMessage: 'Oman' },
    PA: { id: 'country.PA', defaultMessage: 'Panama' },
    PE: { id: 'country.PE', defaultMessage: 'Peru' },
    PF: { id: 'country.PF', defaultMessage: 'French Polynesia' },
    PG: { id: 'country.PG', defaultMessage: 'Papua New Guinea' },
    PH: { id: 'country.PH', defaultMessage: 'Philippines' },
    PK: { id: 'country.PK', defaultMessage: 'Pakistan' },
    PL: { id: 'country.PL', defaultMessage: 'Poland' },
    PM: { id: 'country.PM', defaultMessage: 'Saint Pierre and Miquelon' },
    PN: { id: 'country.PN', defaultMessage: 'Pitcairn' },
    PR: { id: 'country.PR', defaultMessage: 'Puerto Rico' },
    PS: { id: 'country.PS', defaultMessage: 'Palestine, State of' },
    PT: { id: 'country.PT', defaultMessage: 'Portugal' },
    PW: { id: 'country.PW', defaultMessage: 'Palau' },
    PY: { id: 'country.PY', defaultMessage: 'Paraguay' },
    QA: { id: 'country.QA', defaultMessage: 'Qatar' },
    RE: { id: 'country.RE', defaultMessage: 'Reunion' },
    RO: { id: 'country.RO', defaultMessage: 'Romania' },
    RS: { id: 'country.RS', defaultMessage: 'Serbia' },
    RU: { id: 'country.RU', defaultMessage: 'Russian Federation' },
    RW: { id: 'country.RW', defaultMessage: 'Rwanda' },
    SA: { id: 'country.SA', defaultMessage: 'Saudi Arabia' },
    SB: { id: 'country.SB', defaultMessage: 'Solomon Islands' },
    SC: { id: 'country.SC', defaultMessage: 'Seychelles' },
    SD: { id: 'country.SD', defaultMessage: 'Sudan' },
    SE: { id: 'country.SE', defaultMessage: 'Sweden' },
    SG: { id: 'country.SG', defaultMessage: 'Singapore' },
    SH: { id: 'country.SH', defaultMessage: 'Saint Helena' },
    SI: { id: 'country.SI', defaultMessage: 'Slovenia' },
    SJ: { id: 'country.SJ', defaultMessage: 'Svalbard and Jan Mayen' },
    SK: { id: 'country.SK', defaultMessage: 'Slovakia' },
    SL: { id: 'country.SL', defaultMessage: 'Sierra Leone' },
    SM: { id: 'country.SM', defaultMessage: 'San Marino' },
    SN: { id: 'country.SN', defaultMessage: 'Senegal' },
    SO: { id: 'country.SO', defaultMessage: 'Somalia' },
    SR: { id: 'country.SR', defaultMessage: 'Suriname' },
    SS: { id: 'country.SS', defaultMessage: 'South Sudan' },
    ST: { id: 'country.ST', defaultMessage: 'Sao Tome and Principe' },
    SV: { id: 'country.SV', defaultMessage: 'El Salvador' },
    SX: { id: 'country.SX', defaultMessage: 'Sint Maarten (Dutch part)' },
    SY: { id: 'country.SY', defaultMessage: 'Syrian Arab Republic' },
    SZ: { id: 'country.SZ', defaultMessage: 'Swaziland' },
    TC: { id: 'country.TC', defaultMessage: 'Turks and Caicos Islands' },
    TD: { id: 'country.TD', defaultMessage: 'Chad' },
    TF: { id: 'country.TF', defaultMessage: 'French Southern Territories' },
    TG: { id: 'country.TG', defaultMessage: 'Togo' },
    TH: { id: 'country.TH', defaultMessage: 'Thailand' },
    TJ: { id: 'country.TJ', defaultMessage: 'Tajikistan' },
    TK: { id: 'country.TK', defaultMessage: 'Tokelau' },
    TL: { id: 'country.TL', defaultMessage: 'Timor-Leste' },
    TM: { id: 'country.TM', defaultMessage: 'Turkmenistan' },
    TN: { id: 'country.TN', defaultMessage: 'Tunisia' },
    TO: { id: 'country.TO', defaultMessage: 'Tonga' },
    TR: { id: 'country.TR', defaultMessage: 'Turkey' },
    TT: { id: 'country.TT', defaultMessage: 'Trinidad and Tobago' },
    TV: { id: 'country.TV', defaultMessage: 'Tuvalu' },
    TW: { id: 'country.TW', defaultMessage: 'Taiwan' },
    TZ: { id: 'country.TZ', defaultMessage: 'United Republic of Tanzania' },
    UA: { id: 'country.UA', defaultMessage: 'Ukraine' },
    UG: { id: 'country.UG', defaultMessage: 'Uganda' },
    UY: { id: 'country.UY', defaultMessage: 'Uruguay' },
    UZ: { id: 'country.UZ', defaultMessage: 'Uzbekistan' },
    VA: { id: 'country.VA', defaultMessage: 'Holy See (Vatican City State)' },
    VC: { id: 'country.VC', defaultMessage: 'Saint Vincent and the Grenadines' },
    VE: { id: 'country.VE', defaultMessage: 'Venezuela' },
    VG: { id: 'country.VG', defaultMessage: 'British Virgin Islands' },
    VI: { id: 'country.VI', defaultMessage: 'US Virgin Islands' },
    VN: { id: 'country.VN', defaultMessage: 'Vietnam' },
    VU: { id: 'country.VU', defaultMessage: 'Vanuatu' },
    WF: { id: 'country.WF', defaultMessage: 'Wallis and Futuna' },
    WS: { id: 'country.WS', defaultMessage: 'Samoa' },
    XK: { id: 'country.XK', defaultMessage: 'Kosovo' },
    YE: { id: 'country.YE', defaultMessage: 'Yemen' },
    YT: { id: 'country.YT', defaultMessage: 'Mayotte' },
    ZA: { id: 'country.ZA', defaultMessage: 'South Africa' },
    ZM: { id: 'country.ZM', defaultMessage: 'Zambia' },
    ZW: { id: 'country.ZW', defaultMessage: 'Zimbabwe' },
})

interface CountryType {
    code: string
    label: MessageDescriptor
    suggested?: boolean
}

export const CA = 'CA'
export const US = 'US'

const countries: readonly CountryType[] = [
    { code: CA, label: countryMessages.CA, suggested: true },
    { code: US, label: countryMessages.US, suggested: true },
    { code: 'AD', label: countryMessages.AD },
    { code: 'AE', label: countryMessages.AE },
    { code: 'AF', label: countryMessages.AF },
    { code: 'AG', label: countryMessages.AG },
    { code: 'AI', label: countryMessages.AI },
    { code: 'AL', label: countryMessages.AL },
    { code: 'AM', label: countryMessages.AM },
    { code: 'AO', label: countryMessages.AO },
    { code: 'AQ', label: countryMessages.AQ },
    { code: 'AR', label: countryMessages.AR },
    { code: 'AS', label: countryMessages.AS },
    { code: 'AT', label: countryMessages.AT },
    { code: 'AU', label: countryMessages.AU },
    { code: 'AW', label: countryMessages.AW },
    { code: 'AX', label: countryMessages.AX },
    { code: 'AZ', label: countryMessages.AZ },
    { code: 'BA', label: countryMessages.BA },
    { code: 'BB', label: countryMessages.BB },
    { code: 'BD', label: countryMessages.BD },
    { code: 'BE', label: countryMessages.BE },
    { code: 'BF', label: countryMessages.BF },
    { code: 'BG', label: countryMessages.BG },
    { code: 'BH', label: countryMessages.BH },
    { code: 'BI', label: countryMessages.BI },
    { code: 'BJ', label: countryMessages.BJ },
    { code: 'BL', label: countryMessages.BL },
    { code: 'BM', label: countryMessages.BM },
    { code: 'BN', label: countryMessages.BN },
    { code: 'BO', label: countryMessages.BO },
    { code: 'BR', label: countryMessages.BR },
    { code: 'BS', label: countryMessages.BS },
    { code: 'BT', label: countryMessages.BT },
    { code: 'BV', label: countryMessages.BV },
    { code: 'BW', label: countryMessages.BW },
    { code: 'BY', label: countryMessages.BY },
    { code: 'BZ', label: countryMessages.BZ },
    { code: 'CC', label: countryMessages.CC },
    { code: 'CD', label: countryMessages.CD },
    { code: 'CF', label: countryMessages.CF },
    { code: 'CG', label: countryMessages.CG },
    { code: 'CH', label: countryMessages.CH },
    { code: 'CI', label: countryMessages.CI },
    { code: 'CK', label: countryMessages.CK },
    { code: 'CL', label: countryMessages.CL },
    { code: 'CM', label: countryMessages.CM },
    { code: 'CN', label: countryMessages.CN },
    { code: 'CO', label: countryMessages.CO },
    { code: 'CR', label: countryMessages.CR },
    { code: 'CU', label: countryMessages.CU },
    { code: 'CV', label: countryMessages.CV },
    { code: 'CW', label: countryMessages.CW },
    { code: 'CX', label: countryMessages.CX },
    { code: 'CY', label: countryMessages.CY },
    { code: 'CZ', label: countryMessages.CZ },
    { code: 'DE', label: countryMessages.DE },
    { code: 'DJ', label: countryMessages.DJ },
    { code: 'DK', label: countryMessages.DK },
    { code: 'DM', label: countryMessages.DM },
    { code: 'DO', label: countryMessages.DO },
    { code: 'DZ', label: countryMessages.DZ },
    { code: 'EC', label: countryMessages.EC },
    { code: 'EE', label: countryMessages.EE },
    { code: 'EG', label: countryMessages.EG },
    { code: 'EH', label: countryMessages.EH },
    { code: 'ER', label: countryMessages.ER },
    { code: 'ES', label: countryMessages.ES },
    { code: 'ET', label: countryMessages.ET },
    { code: 'FI', label: countryMessages.FI },
    { code: 'FJ', label: countryMessages.FJ },
    { code: 'FK', label: countryMessages.FK },
    { code: 'FM', label: countryMessages.FM },
    { code: 'FO', label: countryMessages.FO },
    { code: 'FR', label: countryMessages.FR },
    { code: 'GA', label: countryMessages.GA },
    { code: 'GB', label: countryMessages.GB },
    { code: 'GD', label: countryMessages.GD },
    { code: 'GE', label: countryMessages.GE },
    { code: 'GF', label: countryMessages.GF },
    { code: 'GG', label: countryMessages.GG },
    { code: 'GH', label: countryMessages.GH },
    { code: 'GI', label: countryMessages.GI },
    { code: 'GL', label: countryMessages.GL },
    { code: 'GM', label: countryMessages.GM },
    { code: 'GN', label: countryMessages.GN },
    { code: 'GP', label: countryMessages.GP },
    { code: 'GQ', label: countryMessages.GQ },
    { code: 'GR', label: countryMessages.GR },
    { code: 'GS', label: countryMessages.GS },
    { code: 'GT', label: countryMessages.GT },
    { code: 'GU', label: countryMessages.GU },
    { code: 'GW', label: countryMessages.GW },
    { code: 'GY', label: countryMessages.GY },
    { code: 'HK', label: countryMessages.HK },
    { code: 'HM', label: countryMessages.HM },
    { code: 'HN', label: countryMessages.HN },
    { code: 'HR', label: countryMessages.HR },
    { code: 'HT', label: countryMessages.HT },
    { code: 'HU', label: countryMessages.HU },
    { code: 'ID', label: countryMessages.ID },
    { code: 'IE', label: countryMessages.IE },
    { code: 'IL', label: countryMessages.IL },
    { code: 'IM', label: countryMessages.IM },
    { code: 'IN', label: countryMessages.IN },
    { code: 'IO', label: countryMessages.IO },
    { code: 'IQ', label: countryMessages.IQ },
    { code: 'IR', label: countryMessages.IR },
    { code: 'IS', label: countryMessages.IS },
    { code: 'IT', label: countryMessages.IT },
    { code: 'JE', label: countryMessages.JE },
    { code: 'JM', label: countryMessages.JM },
    { code: 'JO', label: countryMessages.JO },
    { code: 'JP', label: countryMessages.JP },
    { code: 'KE', label: countryMessages.KE },
    { code: 'KG', label: countryMessages.KG },
    { code: 'KH', label: countryMessages.KH },
    { code: 'KI', label: countryMessages.KI },
    { code: 'KM', label: countryMessages.KM },
    { code: 'KN', label: countryMessages.KN },
    { code: 'KP', label: countryMessages.KP },
    { code: 'KR', label: countryMessages.KR },
    { code: 'KW', label: countryMessages.KW },
    { code: 'KY', label: countryMessages.KY },
    { code: 'KZ', label: countryMessages.KZ },
    { code: 'LA', label: countryMessages.LA },
    { code: 'LB', label: countryMessages.LB },
    { code: 'LC', label: countryMessages.LC },
    { code: 'LI', label: countryMessages.LI },
    { code: 'LK', label: countryMessages.LK },
    { code: 'LR', label: countryMessages.LR },
    { code: 'LS', label: countryMessages.LS },
    { code: 'LT', label: countryMessages.LT },
    { code: 'LU', label: countryMessages.LU },
    { code: 'LV', label: countryMessages.LV },
    { code: 'LY', label: countryMessages.LY },
    { code: 'MA', label: countryMessages.MA },
    { code: 'MC', label: countryMessages.MC },
    { code: 'MD', label: countryMessages.MD },
    { code: 'ME', label: countryMessages.ME },
    { code: 'MF', label: countryMessages.MF },
    { code: 'MG', label: countryMessages.MG },
    { code: 'MH', label: countryMessages.MH },
    { code: 'MK', label: countryMessages.MK },
    { code: 'ML', label: countryMessages.ML },
    { code: 'MM', label: countryMessages.MM },
    { code: 'MN', label: countryMessages.MN },
    { code: 'MO', label: countryMessages.MO },
    { code: 'MP', label: countryMessages.MP },
    { code: 'MQ', label: countryMessages.MQ },
    { code: 'MR', label: countryMessages.MR },
    { code: 'MS', label: countryMessages.MS },
    { code: 'MT', label: countryMessages.MT },
    { code: 'MU', label: countryMessages.MU },
    { code: 'MV', label: countryMessages.MV },
    { code: 'MW', label: countryMessages.MW },
    { code: 'MX', label: countryMessages.MX },
    { code: 'MY', label: countryMessages.MY },
    { code: 'MZ', label: countryMessages.MZ },
    { code: 'NA', label: countryMessages.NA },
    { code: 'NC', label: countryMessages.NC },
    { code: 'NE', label: countryMessages.NE },
    { code: 'NF', label: countryMessages.NF },
    { code: 'NG', label: countryMessages.NG },
    { code: 'NI', label: countryMessages.NI },
    { code: 'NL', label: countryMessages.NL },
    { code: 'NO', label: countryMessages.NO },
    { code: 'NP', label: countryMessages.NP },
    { code: 'NR', label: countryMessages.NR },
    { code: 'NU', label: countryMessages.NU },
    { code: 'NZ', label: countryMessages.NZ },
    { code: 'OM', label: countryMessages.OM },
    { code: 'PA', label: countryMessages.PA },
    { code: 'PE', label: countryMessages.PE },
    { code: 'PF', label: countryMessages.PF },
    { code: 'PG', label: countryMessages.PG },
    { code: 'PH', label: countryMessages.PH },
    { code: 'PK', label: countryMessages.PK },
    { code: 'PL', label: countryMessages.PL },
    { code: 'PM', label: countryMessages.PM },
    { code: 'PN', label: countryMessages.PN },
    { code: 'PR', label: countryMessages.PR },
    { code: 'PS', label: countryMessages.PS },
    { code: 'PT', label: countryMessages.PT },
    { code: 'PW', label: countryMessages.PW },
    { code: 'PY', label: countryMessages.PY },
    { code: 'QA', label: countryMessages.QA },
    { code: 'RE', label: countryMessages.RE },
    { code: 'RO', label: countryMessages.RO },
    { code: 'RS', label: countryMessages.RS },
    { code: 'RU', label: countryMessages.RU },
    { code: 'RW', label: countryMessages.RW },
    { code: 'SA', label: countryMessages.SA },
    { code: 'SB', label: countryMessages.SB },
    { code: 'SC', label: countryMessages.SC },
    { code: 'SD', label: countryMessages.SD },
    { code: 'SE', label: countryMessages.SE },
    { code: 'SG', label: countryMessages.SG },
    { code: 'SH', label: countryMessages.SH },
    { code: 'SI', label: countryMessages.SI },
    { code: 'SJ', label: countryMessages.SJ },
    { code: 'SK', label: countryMessages.SK },
    { code: 'SL', label: countryMessages.SL },
    { code: 'SM', label: countryMessages.SM },
    { code: 'SN', label: countryMessages.SN },
    { code: 'SO', label: countryMessages.SO },
    { code: 'SR', label: countryMessages.SR },
    { code: 'SS', label: countryMessages.SS },
    { code: 'ST', label: countryMessages.ST },
    { code: 'SV', label: countryMessages.SV },
    { code: 'SX', label: countryMessages.SX },
    { code: 'SY', label: countryMessages.SY },
    { code: 'SZ', label: countryMessages.SZ },
    { code: 'TC', label: countryMessages.TC },
    { code: 'TD', label: countryMessages.TD },
    { code: 'TF', label: countryMessages.TF },
    { code: 'TG', label: countryMessages.TG },
    { code: 'TH', label: countryMessages.TH },
    { code: 'TJ', label: countryMessages.TJ },
    { code: 'TK', label: countryMessages.TK },
    { code: 'TL', label: countryMessages.TL },
    { code: 'TM', label: countryMessages.TM },
    { code: 'TN', label: countryMessages.TN },
    { code: 'TO', label: countryMessages.TO },
    { code: 'TR', label: countryMessages.TR },
    { code: 'TT', label: countryMessages.TT },
    { code: 'TV', label: countryMessages.TV },
    { code: 'TW', label: countryMessages.TW },
    { code: 'TZ', label: countryMessages.TZ },
    { code: 'UA', label: countryMessages.UA },
    { code: 'UG', label: countryMessages.UG },
    { code: 'UY', label: countryMessages.UY },
    { code: 'UZ', label: countryMessages.UZ },
    { code: 'VA', label: countryMessages.VA },
    { code: 'VC', label: countryMessages.VC },
    { code: 'VE', label: countryMessages.VE },
    { code: 'VG', label: countryMessages.VG },
    { code: 'VI', label: countryMessages.VI },
    { code: 'VN', label: countryMessages.VN },
    { code: 'VU', label: countryMessages.VU },
    { code: 'WF', label: countryMessages.WF },
    { code: 'WS', label: countryMessages.WS },
    { code: 'XK', label: countryMessages.XK },
    { code: 'YE', label: countryMessages.YE },
    { code: 'YT', label: countryMessages.YT },
    { code: 'ZA', label: countryMessages.ZA },
    { code: 'ZM', label: countryMessages.ZM },
    { code: 'ZW', label: countryMessages.ZW },
]
