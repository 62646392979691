import { Box, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

import CountrySelect, { CA, US } from 'components/UserResearchPanel/SignupSteps/CountrySelect'
import { HowDidYouHear } from 'components/UserResearchPanel/SignupSteps/HowDidYouHear'
import ProvinceSelect from 'components/UserResearchPanel/SignupSteps/ProvinceSelect'
import { useHandleChange, UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import { CITY, EMAIL, GENDER, GENDER_OTHER, POSTAL_CODE, YEAR_OF_BIRTH } from '../validationSchema'
import { YearSelect } from '../CommonInputs/YearSelect'
import { TextInput } from '../CommonInputs/TextInput'
import { commonMessages } from '../CommonInputs/CommonMessages'
import { QuestionText } from '../CommonInputs/QuestionText'
import { Heading } from '../CommonInputs/Heading'

export const Background = () => {
    const { formData, touched, errors, isInitialSignup } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()
    const { formatMessage } = useIntl()

    const isOtherGenderSelected = formData[GENDER] === 'other'

    return (
        <Box>
            <Heading>
                <FormattedMessage description="User Research Panel" defaultMessage="Background" />
            </Heading>
            <Box>
                <FormControl sx={styles.formControl} required>
                    <QuestionText required>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Which gender do you identify with?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage {...commonMessages.selectOneOption} />
                    </FormHelperText>
                    <RadioGroup
                        name="gender"
                        value={String(formData[GENDER])}
                        onChange={e => handleChange(GENDER, e.target.value)}
                        aria-labelledby="gender-group-label"
                    >
                        <FormControlLabel
                            value="man"
                            control={<Radio />}
                            label={
                                <FormattedMessage
                                    description="User Research Panel gender option"
                                    defaultMessage="Man"
                                />
                            }
                        />
                        <FormControlLabel
                            value="woman"
                            control={<Radio />}
                            label={
                                <FormattedMessage
                                    description="User Research Panel gender option"
                                    defaultMessage="Woman"
                                />
                            }
                        />
                        <FormControlLabel
                            value="gender-fluid"
                            control={<Radio />}
                            label={
                                <FormattedMessage
                                    description="User Research Panel gender option"
                                    defaultMessage="Gender-fluid, non-binary, and/or Two-Spirit"
                                />
                            }
                        />
                        <FormControlLabel
                            value="self-describe"
                            control={<Radio />}
                            label={
                                <FormattedMessage
                                    description="User Research Panel gender option"
                                    defaultMessage="Prefer to self-describe"
                                />
                            }
                        />
                        <FormControlLabel
                            value="prefer-not-to-say"
                            control={<Radio />}
                            label={
                                <FormattedMessage
                                    description="User Research Panel gender option"
                                    defaultMessage="Prefer not to say"
                                />
                            }
                        />
                        <FormControlLabel
                            value="other"
                            control={<Radio />}
                            label={
                                <FormattedMessage
                                    description="User Research Panel gender option"
                                    defaultMessage="Other"
                                />
                            }
                        />
                    </RadioGroup>
                    {touched[GENDER] && errors[GENDER] && <FormHelperText error>{errors[GENDER]}</FormHelperText>}
                </FormControl>
                {isOtherGenderSelected && (
                    <TextInput
                        dataKey={GENDER_OTHER}
                        placeholder={formatMessage({
                            description: 'User Research Panel',
                            defaultMessage: 'Other',
                        })}
                    />
                )}
            </Box>
            <Box sx={styles.formControl}>
                <FormControl sx={styles.formControl} required>
                    <QuestionText required>
                        <FormattedMessage description="User Research Panel" defaultMessage="What year were you born?" />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Please pick your birth year from the drop down."
                        />
                    </FormHelperText>
                    <YearSelect dataKey={YEAR_OF_BIRTH} />
                </FormControl>
            </Box>
            <Box sx={styles.formControlExtraSpace}>
                <FormControl component="fieldset" required>
                    <QuestionText required>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Where is your primary residence?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Please select the appropriate options."
                        />
                    </FormHelperText>
                    <Box sx={styles.flex}>
                        <CountrySelect />
                        {(formData.country === CA || formData.country === US) && <ProvinceSelect />}
                    </Box>
                    <Box sx={styles.flex}>
                        <TextInput
                            fullWidth={false}
                            dataKey={CITY}
                            sx={styles.input}
                            placeholder={formatMessage({
                                description: 'User Research Panel',
                                defaultMessage: 'City/Town',
                            })}
                        />
                        <TextInput
                            fullWidth={false}
                            dataKey={POSTAL_CODE}
                            sx={styles.input}
                            placeholder={formatMessage({
                                description: 'User Research Panel',
                                defaultMessage: 'Postal/ZIP Code',
                            })}
                        />
                    </Box>
                </FormControl>
            </Box>
            <FormControl sx={styles.formControlExtraSpace}>
                <QuestionText required>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="What is your preferred email for us to contact you?"
                    />
                </QuestionText>
                <TextInput
                    sx={styles.textInput}
                    dataKey={EMAIL}
                    placeholder={formatMessage({
                        description: 'User Research Panel',
                        defaultMessage: 'Email',
                    })}
                />
            </FormControl>
            {isInitialSignup && <HowDidYouHear />}
        </Box>
    )
}

const styles = {
    flex: {
        display: 'flex' as const,
        gap: 2,
        flexWrap: 'wrap' as const,
        marginTop: 1,
        marginBottom: 1,
    },
    input: {
        width: 300,
    },
    ageInput: {
        width: 300,
        marginTop: 1,
    },
    textInput: {
        marginTop: 1,
        width: 300,
    },
    section: {
        marginTop: 6,
    },
    formControl: {
        marginTop: 2,
    },
    formControlExtraSpace: {
        marginTop: 4,
    },
}
