import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, FormControlLabel, Radio, FormHelperText, Select, MenuItem } from '@mui/material'

import { messages } from './PersonalInvolvement'
import { OTHER, trainingCourseMessages } from './TrainingCourseConstants'
import { isLevelOneOrHigher } from './ExperienceConstants'
import { TRAINING_COURSE_OPTIONS } from './TrainingCourseConstants'
import { useHandleChange, UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import {
    HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED,
    HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_OTHER,
    HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_YEAR,
    TRAINING_COURSE_COUNT,
} from './ExperienceConstants'
import { YearSelect } from 'components/UserResearchPanel/CommonInputs/YearSelect'
import { COURSE_COUNT_OPTIONS } from './TrainingCourseConstants'
import { TextInput } from 'components/UserResearchPanel/CommonInputs/TextInput'
import { commonMessages } from 'components/UserResearchPanel/CommonInputs/CommonMessages'
import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'

export const TrainingCourses = () => {
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()
    const { formatMessage } = useIntl()

    const handleTrainingCourseChange = (trainingCourse: string) => {
        handleChange(HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED, trainingCourse)
    }

    const showOtherField = formData[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED] === OTHER
    const showYearField =
        typeof formData[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED] === 'string' &&
        isLevelOneOrHigher(formData[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED] as string)

    return (
        <>
            <FormControl
                sx={styles.formControl}
                error={
                    touched[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED] &&
                    !!errors[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED]
                }
                aria-invalid={
                    touched[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED] &&
                    !!errors[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED]
                }
            >
                <QuestionText required>
                    <FormattedMessage {...messages.training_courses} />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectOneOption} />
                </FormHelperText>
                {TRAINING_COURSE_OPTIONS.map(option => (
                    <FormControlLabel
                        key={option}
                        control={<Radio />}
                        checked={formData[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED] === option}
                        onChange={() => handleTrainingCourseChange(option)}
                        label={
                            <FormattedMessage
                                {...trainingCourseMessages[option as keyof typeof trainingCourseMessages]}
                            />
                        }
                    />
                ))}
            </FormControl>
            {showOtherField && (
                <TextInput
                    dataKey={HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_OTHER}
                    placeholder={formatMessage(messages.otherAvalancheSafetyTrainingCourse)}
                    sx={styles.otherInput}
                    fullWidth
                />
            )}
            {showYearField && (
                <>
                    <FormControl
                        sx={styles.formControl}
                        error={
                            touched[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_YEAR] &&
                            !!errors[HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_YEAR]
                        }
                    >
                        <QuestionText required>
                            <FormattedMessage {...messages.yearCompletedHighestLevelTrainingCourse} />
                        </QuestionText>
                        <FormHelperText>
                            <FormattedMessage {...messages.yearCompletedHighestLevelTrainingCourseHelperText} />
                        </FormHelperText>
                        <YearSelect dataKey={HIGHEST_LEVEL_TRAINING_COURSE_COMPLETED_YEAR} />
                    </FormControl>

                    <FormControl
                        sx={styles.formControl}
                        error={touched[TRAINING_COURSE_COUNT] && !!errors[TRAINING_COURSE_COUNT]}
                    >
                        <QuestionText required>
                            <FormattedMessage {...messages.courseCount} />
                        </QuestionText>
                        <FormHelperText>
                            <FormattedMessage {...messages.courseCountHelperText} />
                        </FormHelperText>
                        <Select
                            sx={styles.dropdown}
                            value={formData[TRAINING_COURSE_COUNT] || ''}
                            onChange={e => {
                                handleChange(TRAINING_COURSE_COUNT, e.target.value as string)
                            }}
                            displayEmpty
                        >
                            <MenuItem value="">
                                <FormattedMessage
                                    description="User Research Panel year dropdown placeholder"
                                    defaultMessage="Select number of courses"
                                />
                            </MenuItem>
                            {COURSE_COUNT_OPTIONS.map(count => (
                                <MenuItem key={count} value={count}>
                                    <FormattedMessage
                                        {...trainingCourseMessages[count as keyof typeof trainingCourseMessages]}
                                    />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )}
        </>
    )
}

const styles = {
    formControl: {
        marginTop: 4,
        width: '100%',
    },
    otherInput: {
        marginTop: 2,
    },
    dropdown: {
        width: 300,
        marginTop: 1,
    },
}
